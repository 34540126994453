import { AnimatePresence, motion } from "framer-motion";
import {
  AlertCircle, ArrowRight, Eye, EyeOff, Loader2, Lock, Mail, MailOpen,
  UserPlus, Shield, Check, Info, Clock, Award, Star
} from "lucide-react";
import AccessDenied from "./AccessDenied";
import axios from "axios";
import { API_URL } from "components/api_config";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useSearchParams } from "react-router-dom";
import { RegisterSuccess } from "./AuthPage";


interface InviteCodeInfo {
  code: string;
  used: boolean;
  creator: string;
  createdAt: string;
}

interface RegisterFormData {
  username: string;
  email: string;
  password: string;
}

interface FormError {
  message: string;
  isVisible: boolean;
  type: "error" | "warning" | "info";
  code: string;
}

export const RegisterPage: React.FC = () => {
  const formRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLImageElement>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const [inviteValid, setInviteValid] = useState(false);
  const [inviteInfo, setInviteInfo] = useState<InviteCodeInfo | null>(null);
  const [inviteError, setInviteError] = useState("");
  const [error, setError] = useState<FormError>({
    message: "",
    isVisible: false,
    type: "error",
    code: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("invite");
  const [showContent, setShowContent] = useState(false);

  const [formData, setFormData] = useState<RegisterFormData>({
    username: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    const verifyInviteCode = async () => {
      if (!inviteCode) {
        setInviteError("Invitation code is required to register");
        setTimeout(() => {
          setIsVerifying(false);
        }, 1000);
        return;
      }

      try {
        const response = await axios.get(
          `${API_URL}/invite/${inviteCode}/creator`
        );

        if (response.data.code === "SUCCESS") {
          setInviteInfo(response.data.inviteCode);
          
          if (response.data.inviteCode.used) {
            setInviteError("This invitation code has already been used");
            setInviteValid(false);
          } else {
            setInviteValid(true);
          }
        } else {
          setInviteError("Invalid invitation code");
          setInviteValid(false);
        }
      } catch (err) {
        setInviteError("Invalid or expired invitation code");
        setInviteValid(false);
      } finally {
        setTimeout(() => {
          setIsVerifying(false);
        }, 1000);
      }
    };

    verifyInviteCode();
  }, [inviteCode]);

  useEffect(() => {
    if (!isVerifying) {
      setTimeout(() => {
        setShowContent(true);
      }, 300);
    }
  }, [isVerifying]);

  useEffect(() => {
    if (showContent && inviteValid && formRef.current) {
      const ctx = gsap.context(() => {
        gsap.from(logoRef.current, {
          duration: 1,
          y: -50,
          opacity: 0,
          ease: "power3.out",
          delay: 0.3,
        });

        gsap.from(".auth-card", {
          duration: 0.8,
          y: 30,
          opacity: 0,
          ease: "power3.out",
          delay: 0.5,
        });

        gsap.from(".invite-card", {
          duration: 0.8,
          y: -30,
          opacity: 0,
          ease: "power3.out",
          delay: 0.5,
        });

        gsap.from(".form-element", {
          duration: 0.6,
          y: 15,
          opacity: 0,
          stagger: 0.15,
          ease: "power2.out",
          delay: 0.7,
        });

        gsap.to(".invite-card", {
          boxShadow: "0 0 20px rgba(97, 27, 226, 0.3), 0 0 10px rgba(97, 27, 226, 0.2) inset",
          duration: 2,
          repeat: -1,
          yoyo: true,
          ease: "sine.inOut",
        });
        
        gsap.to(".bg-circle", {
          rotation: 360,
          duration: 120,
          repeat: -1,
          ease: "none",
        });
      }, formRef);

      return () => ctx.revert();
    }
  }, [showContent, inviteValid]);

  const calculatePasswordStrength = (password: string): number => {
    if (!password) return 0;

    let score = 0;
    if (password.length >= 8) score += 1;
    if (password.length >= 12) score += 1;
    if (/[A-Z]/.test(password)) score += 1;
    if (/[0-9]/.test(password)) score += 1;
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    return Math.min(score, 5);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "password") {
      setPasswordStrength(calculatePasswordStrength(value));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setError({
      message: "",
      isVisible: false,
      type: "error",
      code: "",
    });

    try {
      const response = await axios.post(`${API_URL}/auth/register`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        pfp: null,
        inviteCode: inviteCode,
      });

      if (response.status === 201) {
        try {
            await axios.post(`${API_URL}/invite/use`, {
              code: inviteCode,
              username: formData.username
            });

            console.log("Invitation code marked as used");
          } catch (inviteErr) {
            console.error("Failed to mark invitation as used:", inviteErr);
          }
        setIsSuccess(true);
      }
    } catch (err) {
      let errorMessage = "An error occurred during registration";
      let errorType: "error" | "warning" = "error";
      let errorCode = "REGISTRATION_ERROR";

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 409) {
          errorMessage = "Username or email already exists";
          errorCode = "DUPLICATE_USER";
        } else if (err.response?.status === 400) {
          errorMessage = "Invalid input data";
          errorCode = "INVALID_INPUT";
        } else if (err.response?.status === 422) {
          errorMessage = "Invalid invitation code";
          errorCode = "INVALID_INVITE";
        } else {
          errorMessage = err.response?.data?.message || errorMessage;
        }
      }

      setError({
        message: errorMessage,
        isVisible: true,
        type: errorType,
        code: errorCode,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = (): boolean => {
    return (
      formData.username.length >= 3 &&
      formData.email.includes("@") &&
      formData.password.length >= 8
    );
  };

  const getPasswordStrengthLabel = () => {
    const labels = ["Very Weak", "Weak", "Fair", "Good", "Strong", "Very Strong"];
    return labels[passwordStrength];
  };

  const getPasswordStrengthColor = () => {
    const colors = [
      "bg-red-500",
      "bg-orange-500",
      "bg-yellow-500",
      "bg-lime-500",
      "bg-green-500",
      "bg-emerald-500",
    ];
    return colors[passwordStrength];
  };

  const getErrorStyles = () => {
    switch (error.type) {
      case "warning":
        return "bg-yellow-500/10 border-yellow-500/50 text-yellow-500";
      case "info":
        return "bg-blue-500/10 border-blue-500/50 text-blue-500";
      default:
        return "bg-red-500/10 border-red-500/50 text-red-500";
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (isSuccess) {
    return <RegisterSuccess email={formData.email} />;
  }

  if (isVerifying) {
    return (
      <div className="min-h-screen bg-[#0E1015] flex items-center justify-center">
        <div className="flex flex-col items-center p-6 max-w-md text-center">
          <div className="w-20 h-20 rounded-full bg-[#611BE2]/20 flex items-center justify-center mb-6">
            <Loader2 className="animate-spin text-[#611BE2] w-10 h-10" />
          </div>
          <h2 className="text-2xl font-bold text-white mb-2">
            Verifying Invitation
          </h2>
          <p className="text-gray-400 mb-8">
            Please wait while we verify your invitation code
          </p>
          <div className="w-full max-w-md h-2 bg-[#1D1F28] rounded-full overflow-hidden">
            <div className="h-full bg-[#611BE2] animate-pulse rounded-full"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!inviteValid) {
    return <AccessDenied reason={inviteError} />;
  }


  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23] flex items-center justify-center p-4 relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="bg-circle absolute w-[800px] h-[800px] rounded-full bg-gradient-to-r from-[#611BE2]/20 to-transparent opacity-20 -top-[400px] -right-[400px] blur-xl"></div>
        <div className="bg-circle absolute w-[600px] h-[600px] rounded-full bg-gradient-to-r from-[#8B5CF6]/20 to-transparent opacity-20 -bottom-[300px] -left-[300px] blur-xl"></div>
        <div className="absolute top-0 left-0 w-full h-full opacity-[0.03] bg-[radial-gradient(circle,_rgba(255,255,255,0.1)_1px,_transparent_1px)] bg-[length:20px_20px]"></div>
      </div>

      <div ref={formRef} className="w-full max-w-5xl relative z-10 py-8">
        <div className="flex flex-col items-center mb-6">
          <motion.img
            ref={logoRef}
            src="/assets/images/big-empire-drop-logo.png"
            alt="Empire Drop Logo"
            className="h-16 mb-2"
          />
          <div className="text-center mb-2">
            <h1 className="text-3xl font-bold text-white tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-white via-indigo-200 to-white">
              Welcome to EmpireDrop
            </h1>
            <p className="text-gray-400">
              Discover amazing Streaming Tools
            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          {inviteInfo && (
            <div className="invite-card lg:w-2/5 bg-gradient-to-br from-[#1F1B30] to-[#271D3B] p-6 rounded-xl border border-[#611BE2]/30 shadow-lg shadow-[#611BE2]/5 self-start lg:sticky lg:top-8">
              <div className="relative">
                <div className="absolute -top-3 -right-3 bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] px-3 py-1 rounded-full text-xs font-medium text-white shadow-lg">
                  Exclusive Access
                </div>

                <div className="flex flex-col items-center text-center mb-4">
                  <div className="p-4 bg-[#611BE2]/20 rounded-full mb-4">
                    <MailOpen size={36} className="text-[#8B5CF6]" />
                  </div>
                  <h3 className="text-white font-bold text-xl mb-1">
                    You've Been Invited!
                  </h3>
                  <div className="w-12 h-1 bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] rounded-full mb-4"></div>
                </div>

                <div className="space-y-5">
                  <div className="bg-[#1A1C23]/50 p-4 rounded-lg border border-gray-800/50">
                    <p className="text-gray-300 mb-1">Invited by</p>
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 rounded-full bg-gradient-to-br from-[#611BE2] to-[#8B5CF6] flex items-center justify-center text-lg font-bold text-white">
                        {inviteInfo.creator.charAt(0).toUpperCase()}
                      </div>
                      <div>
                        <p className="text-[#8B5CF6] font-semibold text-lg">
                          {inviteInfo.creator}
                        </p>
                        <p className="text-xs text-gray-500">
                          EmpireDrop Team
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-[#1A1C23]/50 p-4 rounded-lg border border-gray-800/50">
                    <div className="flex items-center gap-2 text-gray-300 mb-1">
                      <Clock size={14} className="text-[#8B5CF6]" />
                      <span>Invitation Details</span>
                    </div>
                    <p className="text-sm text-gray-400">
                      Sent on{" "}
                      <span className="text-white">
                        {formatDate(inviteInfo.createdAt)}
                      </span>
                    </p>
                    <p className="text-sm text-gray-400">
                      Code:{" "}
                      <span className="font-mono text-white bg-black/30 px-1 py-0.5 rounded">
                        {inviteInfo.code}
                      </span>
                    </p>
                  </div>

                  

                  <div className="p-4 rounded-lg bg-[#1A1C23]/50 border border-gray-800/50">
                    <div className="flex items-center gap-2">
                      <Info size={14} className="text-[#8B5CF6]" />
                      <p className="text-gray-300 text-sm">
                        This invitation can only be used once
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Formulaire d'inscription */}
          <div className="auth-card lg:w-3/5 bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-6 lg:p-8 rounded-xl shadow-2xl backdrop-blur-lg border border-gray-800/50">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold text-white mb-2">
                Create Your Account
              </h2>
              <p className="text-gray-400">
                Fill out the form to get started
              </p>
            </div>

            {error.isVisible && (
              <div className={`error-message border rounded-lg p-4 mb-6 flex items-center space-x-2 ${getErrorStyles()}`}>
                <AlertCircle className="text-current flex-shrink-0" size={20} />
                <p className="text-sm">{error.message}</p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-5">
              <div className="form-element">
                <label className="block text-sm font-medium text-gray-300 mb-1.5">
                  Username
                </label>
                <div className="relative">
                  <UserPlus className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  <input
                    type="text"
                    name="username"
                    placeholder="Choose a username"
                    className="w-full bg-[#0E1015] text-white rounded-lg pl-10 pr-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300 border border-gray-800"
                    value={formData.username}
                    onChange={handleInputChange}
                    disabled={isLoading}
                    autoComplete="username"
                  />
                  {formData.username.length >= 3 && (
                    <Check className="absolute right-3 top-1/2 transform -translate-y-1/2 text-green-500" size={18} />
                  )}
                </div>
                <p className="mt-1.5 text-xs text-gray-400">
                  Username must be at least 3 characters long
                </p>
              </div>

              <div className="form-element">
                <label className="block text-sm font-medium text-gray-300 mb-1.5">
                  Email Address
                </label>
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your email address"
                    className="w-full bg-[#0E1015] text-white rounded-lg pl-10 pr-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300 border border-gray-800"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={isLoading}
                    autoComplete="email"
                  />
                  {formData.email.includes("@") && (
                    <Check className="absolute right-3 top-1/2 transform -translate-y-1/2 text-green-500" size={18} />
                  )}
                </div>
                <p className="mt-1.5 text-xs text-gray-400">
                  We'll never share your email with anyone else
                </p>
              </div>

              <div className="form-element">
                <label className="block text-sm font-medium text-gray-300 mb-1.5">
                  Password
                </label>
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Create a strong password"
                    className="w-full bg-[#0E1015] text-white rounded-lg pl-10 pr-12 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300 border border-gray-800"
                    value={formData.password}
                    onChange={handleInputChange}
                    disabled={isLoading}
                    autoComplete="new-password"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white transition-colors duration-300"
                    disabled={isLoading}
                  >
                    {showPassword ? (
                      <EyeOff size={18} />
                    ) : (
                      <Eye size={18} />
                    )}
                  </button>
                </div>

                {/* Password strength indicator */}
                {formData.password && (
                  <div className="mt-3">
                    <div className="flex justify-between items-center mb-1">
                      <div className="text-xs text-gray-400">
                        Password strength:
                      </div>
                      <div className={`text-xs ${passwordStrength > 2 ? "text-green-400" : "text-amber-400"}`}>
                        {getPasswordStrengthLabel()}
                      </div>
                    </div>
                    <div className="h-1.5 w-full bg-gray-800 rounded-full overflow-hidden flex">
                      {Array.from({ length: 5 }).map((_, i) => (
                        <div
                          key={i}
                          className={`h-full ${i < passwordStrength ? getPasswordStrengthColor() : "bg-transparent"} 
                          transition-all duration-500 ease-out flex-1 ${i > 0 ? "ml-0.5" : ""}`}
                        ></div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div className="form-element pt-3">
                <button
                  type="submit"
                  className="w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white rounded-lg py-3.5 flex items-center justify-center space-x-2 hover:opacity-90 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg shadow-[#611BE2]/20 hover:shadow-[#611BE2]/30 hover:shadow-xl"
                  disabled={isLoading || !isFormValid()}
                >
                  {isLoading ? (
                    <Loader2 className="animate-spin" size={24} />
                  ) : (
                    <>
                      <span className="font-medium">Create Account</span>
                      <ArrowRight size={18} />
                    </>
                  )}
                </button>
              </div>
            </form>

            <div className="mt-6 text-center text-gray-400">
              <p>
                Already have an account?{" "}
                <a href="/login" className="text-[#8B5CF6] hover:text-white transition-colors duration-300 font-medium">
                  Sign in
                </a>
              </p>
            </div>

       
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;