import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  LogIn, UserPlus, Mail, Lock, Eye, EyeOff, ArrowRight, 
  Loader2, AlertCircle, Check, AlertOctagon, Clock, 
  XCircle, ShieldX, MailOpen, UserX, Info
} from 'lucide-react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import gsap from 'gsap';

const AccessDenied: React.FC<{reason: string}> = ({ reason }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from('.denied-icon', {
        scale: 0,
        rotation: 180,
        opacity: 0,
        duration: 0.8,
        ease: 'elastic.out(1, 0.5)',
        delay: 0.5
      });

      gsap.from('.denied-text', {
        y: 50,
        opacity: 0,
        duration: 0.6,
        stagger: 0.2,
        ease: 'power3.out',
        delay: 1
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={containerRef} className="min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23] flex flex-col items-center justify-center p-4">
      <div className="max-w-md w-full space-y-8 text-center">
        <div className="denied-icon bg-[#1A1C23] p-8 rounded-full inline-block mb-8">
          <div className="bg-red-500/20 p-6 rounded-full">
            <XCircle className="w-16 h-16 text-red-500" />
          </div>
        </div>

        <h2 className="denied-text text-4xl font-bold text-white mb-4">
          Access Denied
        </h2>
        
        <p className="denied-text text-gray-400 text-lg mb-8">
          {reason}
        </p>

        <motion.button
          onClick={() => navigate('/login')}
          className="denied-text mt-8 px-8 py-3 bg-gradient-to-r from-red-600 to-red-400 text-white rounded-lg flex items-center justify-center space-x-2 hover:opacity-90 transition-opacity duration-300 mx-auto"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <span>Return to Login</span>
          <ArrowRight size={20} />
        </motion.button>
      </div>
    </div>
  );
};

export default AccessDenied;