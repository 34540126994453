import { createGlobalStyle } from "styled-components";

export const CustomScrollbarStyles = createGlobalStyle`
  .custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: rgba(26, 28, 35, 0.5);
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #611BE2 0%, #8B5CF6 100%);
    border-radius: 10px;
    border: 2px solid rgba(26, 28, 35, 0.5);
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #7938E0 0%, #9B6BF7 100%);
  }
  
  /* Styles pour Firefox */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #611BE2 rgba(26, 28, 35, 0.5);
  }
`;