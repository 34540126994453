import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Settings,
  MessageSquare,
  Hash,
  CheckCircle2,
  AlertCircle,
  Trash2,
  Trophy,
  Crown,
  Timer,
  Activity,
  StopCircle,
} from "lucide-react";
import axios from "axios";
import { ParticipantsCard } from "./ParticipantCard";
import { WinnerHistory } from "./WinnerHistory";
import { LastWinner } from "./LastWinner";
import { API_URL } from "components/api_config";
import { debounce } from "lodash";
import { ChatMessage, PusherMessage } from "types";
import { GameState, Participant } from "./types";
import WinnerChat from "./WinnerChat";

interface Toast {
  id: number;
  message: string;
  type: "success" | "error";
}

export const DrawSettings: React.FC = () => {
  const [chatKeyword, setChatKeyword] = useState("");
  const [isStoppingDraw, setIsStoppingDraw] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const chatRef = useRef<HTMLDivElement>(null);
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawInitiating, setIsDrawInitiating] = useState(false);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState<number | null>(null);
  const [gameState, setGameState] = useState<GameState>({
    status: 0,
    remainingTime: 0,
  });

  const [inputKeyword, setInputKeyword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const updateKeywordDebounced = useRef(
    debounce(async (keyword: string) => {
      try {
        setIsSaving(true);
        await axios.put(`${API_URL}/overlay/keyword`, { keyword });
        setCurrentKeyword(keyword);
        addToast("Keyword updated successfully!", "success");
      } catch (err) {
        addToast("Failed to update keyword", "error");

        setInputKeyword(currentKeyword);
      } finally {
        setIsSaving(false);
      }
    }, 1000)
  ).current;

  useEffect(() => {
    const fetchKeyword = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/overlay/keyword`);
        const keyword = response.data.data.keyword;
        setCurrentKeyword(keyword);
        setInputKeyword(keyword);
      } catch (err) {
        addToast("Failed to load keyword", "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchKeyword();
    const interval = setInterval(fetchKeyword, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const ws = new WebSocket(
      "wss://ws-us2.pusher.com/app/32cbd69e4b950bf97679?protocol=7&client=js&version=8.4.0-rc2&flash=false"
    );

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          event: "pusher:subscribe",
          data: { auth: "", channel: "chatrooms.29877896.v2" },
        })
      );
    };

    ws.onmessage = (event) => {
      const data: PusherMessage = JSON.parse(event.data);
      if (data.event === "App\\Events\\ChatMessageEvent") {
        const messageData = JSON.parse(data.data);
        setMessages((prev) => [...prev, messageData]);

        if (chatRef.current) {
          chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
      }
    };

    return () => ws.close();
  }, []);

  const addToast = (message: string, type: "success" | "error") => {
    const newToast = { id: Date.now(), message, type };
    setToasts((prev) => [...prev, newToast]);
    setTimeout(
      () => setToasts((prev) => prev.filter((t) => t.id !== newToast.id)),
      3000
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (gameState.status !== 0 && gameState.remainingTime > 0) {
      timer = setInterval(() => {
        setGameState((prev) => ({
          ...prev,
          remainingTime: Math.max(0, prev.remainingTime - 1),
        }));
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [gameState.status, gameState.remainingTime]);

  const stopDraw = async () => {
    setIsStoppingDraw(true);
    try {
      await axios.get(`${API_URL}/game/stop`);
      addToast("Draw stopped successfully", "success");
    } catch (err) {
      addToast("Failed to stop draw", "error");
    } finally {
      setIsStoppingDraw(false);
    }
  };

  useEffect(() => {
    const fetchGameState = async () => {
      try {
        const response = await axios.get(
          "https://empire.selaris.app/api/v1/gastate"
        );
        const newStatus = response.data;
        setGameState((prev) => ({
          status: newStatus,
          remainingTime:
            prev.status === 0 && newStatus !== 0 && newStatus !== 5
              ? 60
              : newStatus === 5
              ? 0
              : prev.remainingTime || 60,
        }));
      } catch (err) {
        addToast("Failed to fetch game state", "error");
      }
    };

    fetchGameState();
    const interval = setInterval(fetchGameState, 1000);
    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  const handleReroll = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDrawInitiating(true);
    try {
      await axios.post(`${API_URL}/game/reroll`);
      addToast("Re-roll successfully completed! 🎲", "success");
    } catch (err) {
      addToast("Failed to re-roll", "error");
    } finally {
      setIsDrawInitiating(false);
    }
  };

  const clearAllParticipants = async () => {
    try {
      await axios.delete(`${API_URL}/participants-all`);
      setParticipants([]);
      addToast("All participants have been cleared", "success");
    } catch (err) {
      addToast("Failed to clear participants", "error");
    }
  };

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const response = await axios.get(`${API_URL}/participants`);
        setParticipants(response.data.data);
      } catch (err) {
        addToast("Failed to load participants", "error");
      }
    };
    fetchParticipants();
    const interval = setInterval(fetchParticipants, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const updateKeyword = async () => {
      if (chatKeyword === currentKeyword) return;
      try {
        setIsLoading(true);
        await axios.put(`${API_URL}/overlay/keyword`, { keyword: chatKeyword });
        setCurrentKeyword(chatKeyword);
        addToast("Keyword updated successfully!", "success");
      } catch (err) {
        addToast("Failed to update keyword", "error");
      } finally {
        setIsLoading(false);
      }
    };
    const timeoutId = setTimeout(() => {
      if (chatKeyword && chatKeyword !== currentKeyword) updateKeyword();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [chatKeyword, currentKeyword]);

  const handleInitiateDraw = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsDrawInitiating(true);
    try {
      await axios.post(`${API_URL}/game/restart`);
      addToast("Draw successfully initiated! 🎲", "success");
      await clearAllParticipants();
    } catch (err) {
      addToast("Failed to initiate draw", "error");
    } finally {
      setIsDrawInitiating(false);
    }
  };

  const handleDeleteParticipant = async (id: number) => {
    setIsDeleteLoading(id);
    try {
      await axios.delete(`${API_URL}/participants/${id}`);
      setParticipants((prev) => prev.filter((p) => p.id !== id));
      addToast("Participant removed successfully", "success");
    } catch (err) {
      addToast("Failed to remove participant", "error");
    } finally {
      setIsDeleteLoading(null);
    }
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
      <style>
        {`
        .scrollbar-custom {
          scrollbar-width: thin;
          scrollbar-color: rgba(139, 92, 246, 0.3) rgba(0, 0, 0, 0.2);
        }

        .scrollbar-custom::-webkit-scrollbar {
          width: 6px;
        }

        .scrollbar-custom::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
        }

        .scrollbar-custom::-webkit-scrollbar-thumb {
          background-color: rgba(139, 92, 246, 0.3);
          border-radius: 3px;
          transition: background-color 0.2s;
        }

        .scrollbar-custom::-webkit-scrollbar-thumb:hover {
          background-color: rgba(139, 92, 246, 0.5);
        }
      `}
      </style>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
        <div className="lg:col-span-8">
          <LastWinner />
          <br />
          <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6 mb-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <Activity className="text-violet-400 mr-2" size={24} />
                <h3 className="text-xl font-semibold text-white">
                  Draw Status
                </h3>
              </div>
              {gameState.status !== 0 && (
                <div className="flex items-center">
                  <Timer className="text-violet-400 mr-2" size={20} />
                  <span className="text-white font-mono text-xl">
                    {formatTime(gameState.remainingTime)}
                  </span>
                </div>
              )}
            </div>
            <div className="bg-black/20 p-4 rounded-xl border border-violet-500/20">
              <span
                className={`text-lg font-medium ${
                  gameState.status === 0 ? "text-gray-400" : "text-green-400"
                }`}
              >
                {gameState.status === 0 ? "No active draw" : "Draw in progress"}
              </span>
            </div>
          </div>
          <br />
          <motion.div
            className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="p-6 border-b border-gray-800/50">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                  <div className="p-3 bg-violet-500/10 rounded-xl ring-1 ring-violet-500/20">
                    <Settings className="w-8 h-8 text-violet-400" />
                  </div>
                  <div className="ml-4">
                    <h2 className="text-3xl font-bold text-white">
                      Draw Management
                    </h2>
                    <p className="text-gray-400 text-sm">
                      Configure and control the draw process
                    </p>
                  </div>
                </div>
                <motion.button
                  onClick={clearAllParticipants}
                  className="px-4 py-2 bg-red-500/10 text-red-400 rounded-xl hover:bg-red-500/20 transition-all duration-200"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Trash2 size={18} className="mr-2 inline-block" />
                  Clear All
                </motion.button>
              </div>

              <form onSubmit={handleInitiateDraw} className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-400">
                    Chat Keyword
                  </label>
                  <div className="relative">
                    <MessageSquare
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-violet-400"
                      size={20}
                    />
                    <input
                      type="text"
                      value={chatKeyword}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 9) {
                          setChatKeyword(value);
                        }
                      }}
                      maxLength={9}
                      className="w-full bg-black/20 text-white border border-violet-500/20 rounded-xl py-3 px-4 pl-12 
    focus:outline-none focus:border-violet-500 transition-all"
                      placeholder="Enter keyword (max 9 chars)"
                    />
                  </div>
                </div>

                <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
                  <div className="flex items-center mb-4">
                    <Hash className="text-violet-400 mr-2" size={24} />
                    <h3 className="text-xl font-semibold text-white">
                      Active Keyword
                    </h3>
                  </div>
                  <div className="bg-black/20 p-3 rounded-xl border border-violet-500/20">
                    <span className="text-gray-400 mr-2">Current:</span>
                    <code className="text-violet-400 font-mono font-medium">
                      !{currentKeyword || "keyword"}
                    </code>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <motion.button
                    type="submit"
                    className="bg-violet-500 hover:bg-violet-600 text-white py-4 px-6 rounded-xl font-semibold
                             transition-all duration-200 flex items-center justify-center space-x-2
                             disabled:opacity-50 disabled:cursor-not-allowed"
                    whileHover={{ scale: isDrawInitiating ? 1 : 1.02 }}
                    whileTap={{ scale: isDrawInitiating ? 1 : 0.98 }}
                    disabled={isDrawInitiating || gameState.status !== 0}
                  >
                    <Trophy size={20} />
                    <span>
                      {isDrawInitiating ? "Initiating..." : "Start New Draw"}
                    </span>
                  </motion.button>

                  <motion.button
                    type="button"
                    onClick={handleReroll}
                    className="bg-gray-900/50 hover:bg-gray-900/70 text-white py-4 px-6 rounded-xl font-semibold
                             border border-violet-500/20 transition-all duration-200 flex items-center justify-center space-x-2
                             disabled:opacity-50 disabled:cursor-not-allowed"
                    whileHover={{ scale: isDrawInitiating ? 1 : 1.02 }}
                    whileTap={{ scale: isDrawInitiating ? 1 : 0.98 }}
                    disabled={isDrawInitiating || gameState.status !== 0}
                  >
                    <Crown size={20} />
                    <span>
                      {isDrawInitiating ? "Rerolling..." : "Re-roll Winner"}
                    </span>
                  </motion.button>
                  <motion.button
                    type="button"
                    onClick={stopDraw}
                    className="bg-red-500/20 hover:bg-red-500/30 text-red-400 py-4 px-6 rounded-xl font-semibold
              border border-red-500/20 transition-all duration-200 flex items-center justify-center space-x-2
              disabled:opacity-50 disabled:cursor-not-allowed"
                    whileHover={{ scale: isStoppingDraw ? 1 : 1.02 }}
                    whileTap={{ scale: isStoppingDraw ? 1 : 0.98 }}
                  >
                    <StopCircle size={20} />
                    <span>{isStoppingDraw ? "Stopping..." : "Stop Draw"}</span>
                  </motion.button>
                  <motion.button
                    type="button"
                    onClick={stopDraw}
                    className="bg-red-500/20 hover:bg-red-500/30 text-red-400 py-4 px-6 rounded-xl font-semibold
              border border-red-500/20 transition-all duration-200 flex items-center justify-center space-x-2
              disabled:opacity-50 disabled:cursor-not-allowed"
                    whileHover={{ scale: isStoppingDraw ? 1 : 1.02 }}
                    whileTap={{ scale: isStoppingDraw ? 1 : 0.98 }}
                  >
                    <AlertCircle size={20} />
                    <span>
                      {isStoppingDraw ? "Stopping..." : "FORCE RESET "}
                    </span>
                  </motion.button>
                </div>
              </form>
            </div>
          </motion.div>

          <div className="mt-8">
            <WinnerHistory />
          </div>
        </div>

        <div className="lg:col-span-4">
          <motion.div
            className="sticky top-8 space-y-6"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Live Chat Section */}
            <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
              <h2 className="text-xl font-semibold text-white mb-4">
                Live Chat
              </h2>
              <div
                ref={chatRef}
                className="h-[400px] overflow-y-auto space-y-2 scrollbar-custom"
              >
                {messages.map((msg) => (
                  <div
                    key={msg.id}
                    className="flex items-start gap-2 p-2 rounded-lg"
                  >
                    <span
                      className="font-medium"
                      style={{ color: msg.sender.identity.color }}
                    >
                      {msg.sender.username}:
                    </span>
                    <span className="text-white">{msg.content}</span>
                  </div>
                ))}
              </div>
            </div>
            <WinnerChat />

            <ParticipantsCard
              participants={participants}
              onDelete={handleDeleteParticipant}
              isDeleteLoading={isDeleteLoading}
              addToast={addToast}
            />
          </motion.div>
        </div>
      </div>

      <div className="fixed bottom-4 right-4 space-y-2 z-50">
        <AnimatePresence>
          {toasts.map((toast) => (
            <motion.div
              key={toast.id}
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: -20, scale: 0.95 }}
              className={`backdrop-blur-xl ${
                toast.type === "success"
                  ? "bg-green-500/80 border-green-400"
                  : "bg-red-500/80 border-red-400"
              } text-white p-4 rounded-xl border flex items-center space-x-2 shadow-lg`}
            >
              {toast.type === "success" ? (
                <CheckCircle2 size={20} />
              ) : (
                <AlertCircle size={20} />
              )}
              <span className="font-medium">{toast.message}</span>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default DrawSettings;
