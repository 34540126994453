export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  
  export const toUTC = (date: Date): string => {
    return date.toISOString().slice(0, 19).replace("T", " ");
  };
  
  export const fromUTC = (dateStr: string): Date => {
    return new Date(dateStr + "Z");
  };
  
  export const formatDateTime = (dateStr: string) => {
    const date = new Date(dateStr);
    date.setHours(date.getHours() + 1);
    return {
      date: date.toLocaleDateString("en-US"),
      time: `${date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })} (UTC)`,
    };
  };
  
  export const getLocalDateTime = (date: string) => {
    const d = new Date(date);
    const offset = d.getTimezoneOffset();
    return new Date(d.getTime() - offset * 60 * 1000);
  };
  
  // Update formatTime function
  export const formatTime = (dateStr: string): string => {
    const date = fromUTC(dateStr);
    return `${date.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })} (UTC)`;
  };
  
  export const getCategoryColor = (category: string) => {
    switch (category) {
      case "stream":
        return "bg-purple-500/20 text-purple-400";
      case "planning":
        return "bg-blue-500/20 text-blue-400";
      case "event":
        return "bg-amber-500/20 text-amber-400";
      default:
        return "bg-violet-500/20 text-violet-400";
    }
  };