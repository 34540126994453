import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Settings, Trophy, Edit2, Save, RotateCcw, Lock, Unlock, Plus, Trash2, Power, CheckCircle, XCircle, Heart, EyeOff, Eye, Play, Sparkles } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';
import Dialog from 'components/ui/Dialog';
import { ConfettiControl, ExtraLifeHideControl } from './LifeAndConfetti';
import { API_URL } from 'components/api_config';

interface Stage {
  id: number;
  position: "1st" | "2nd" | "3rd" | "4th" | "5th";
  prize: number;
  is_active: boolean;
  is_unlocked: boolean;
  minimum_score: number;
  colors: {
    from: string;
    via: string;
    to: string;
    shadow: string;
  };
}

interface NewStageDialog {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (data: { prize: number; minimum_score: number }) => void;
  title: string;
}



const NewStageDialog: React.FC<NewStageDialog> = ({ isOpen, onClose, onConfirm, title }) => {
  const [prize, setPrize] = useState<number>(0);
  const [minimumScore, setMinimumScore] = useState<number>(0);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (prize <= 0) {
      toast.error('Prize must be greater than 0');
      return;
    }
    
    onConfirm({ prize, minimum_score: 500 });
    setPrize(0);
    setMinimumScore(0);
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-[#1A1C23] rounded-lg p-6 w-[400px]"
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        onClick={e => e.stopPropagation()}
      >
        <h3 className="text-xl font-bold text-white mb-4">{title}</h3>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-4 mb-6">
            <div>
              <label className="text-gray-300 block mb-2">Prize (€)</label>
              <input
                type="number"
                value={prize}
                onChange={e => setPrize(Number(e.target.value))}
                className="w-full bg-[#252A34] text-white border border-[#611BE2] rounded px-3 py-2"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 rounded bg-gray-700 text-white hover:bg-gray-600 transition-colors"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 rounded bg-[#611BE2] text-white hover:bg-[#8B5CF6] transition-colors"
            >
              Add Stage
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>

  );
};

const DeleteDialog: React.FC<{ isOpen: boolean; onClose: () => void; onConfirm: () => void }> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-[#1A1C23] rounded-lg p-6 w-[400px]"
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        onClick={e => e.stopPropagation()}
      >
        <h3 className="text-xl font-bold text-white mb-4">Delete Stage</h3>
        <p className="text-gray-300 mb-6">Are you sure you want to delete this stage? This action cannot be undone.</p>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded bg-gray-700 text-white hover:bg-gray-600 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="px-4 py-2 rounded bg-red-600 text-white hover:bg-red-700 transition-colors"
          >
            Delete
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};


const PrizeSettings: React.FC = () => {
  const [stages, setStages] = useState<Stage[]>([]);
  const [editingStage, setEditingStage] = useState<number | null>(null);
  const [editingValue, setEditingValue] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [extraLifeStatus, setExtraLifeStatus] = useState(false);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [isAddStageDialogOpen, setIsAddStageDialogOpen] = useState(false);
  const [stageToDelete, setStageToDelete] = useState<number | null>(null);

  const fetchStages = async () => {
    try {
      const response = await fetch(`${API_URL}/stages`);
      const data = await response.json();
      if (data.data) {
        setStages(data.data);
      }
      setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch stages');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStages();
  }, []);

  const handlePrizeChange = async (id: number) => {
    const newPrize = parseInt(editingValue);
    if (isNaN(newPrize) || newPrize < 0) {
      toast.error('Please enter a valid prize amount');
      return;
    }

    const loadingToast = toast.loading('Updating prize...');
    try {
      const response = await fetch(`${API_URL}/stages/${id}/prize`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ prize: newPrize }),
      });

      if (response.ok) {
        const data = await response.json();
        const updatedStages = stages.map(stage => 
          stage.id === id ? { ...stage, prize: newPrize } : stage
        );
        setStages(updatedStages);
        toast.success('Prize updated successfully', { id: loadingToast });
        setEditingStage(null);
        setEditingValue("");
      } else {
        throw new Error('Failed to update prize');
      }
    } catch (error) {
      toast.error('Failed to update prize', { id: loadingToast });
    }
  };

  const handleStageActivation = async (id: number, isActivating: boolean) => {
    const loadingToast = toast.loading(`${isActivating ? 'Activating' : 'Deactivating'} stage...`);
    try {
      const response = await fetch(`${API_URL}/stages/${id}/activate`, {
        method: 'PATCH',
      });

      if (response.ok) {
        const data = await response.json();
        const updatedStages = stages.map(stage => ({
          ...stage,
          is_active: stage.id === id ? true : false
        }));
        setStages(updatedStages);
        toast.success(`Stage ${isActivating ? 'activated' : 'deactivated'} successfully`, { id: loadingToast });
      } else {
        throw new Error(`Failed to ${isActivating ? 'activate' : 'deactivate'} stage`);
      }
    } catch (error) {
      toast.error(`Failed to ${isActivating ? 'activate' : 'deactivate'} stage`, { id: loadingToast });
    }
  };

  const toggleStageLock = async (id: number, currentStatus: boolean) => {
    const action = currentStatus ? 'Locking' : 'Unlocking';
    const loadingToast = toast.loading(`${action} stage...`);
    
    try {
      const response = await fetch(`${API_URL}/stages/${id}/unlock`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ isUnlocked: !currentStatus }),
      });

      if (response.ok) {
        const data = await response.json();
        const updatedStages = stages.map(stage => 
          stage.id === id ? { ...stage, is_unlocked: !currentStatus } : stage
        );
        setStages(updatedStages);
        toast.success(`Stage ${currentStatus ? 'locked' : 'unlocked'} successfully`, { id: loadingToast });
      } else {
        throw new Error('Failed to update lock status');
      }
    } catch (error) {
      toast.error('Failed to update lock status', { id: loadingToast });
    }
  };

  const handleResetStages = async () => {
    const loadingToast = toast.loading('Resetting all stages and Extra Life...');
    try {
      // Reset stages
      await Promise.all([
        fetch(`${API_URL}/stages/reset`, { method: 'POST' }),
        fetch(`${API_URL}/stages/reset-active`, { method: 'POST' })
      ]);
      
      // Reset and activate Extra Life
      await fetch(`${API_URL}/extra-life/reset`, { method: 'POST' });
      await fetch(`${API_URL}/extra-life/toggle`, { method: 'PATCH' });
      
      // Fetch updated stages
      const response = await fetch(`${API_URL}/stages`);
      const data = await response.json();
      
      if (data.data) {
        // Find and activate the last stage
        const lastStage = data.data[data.data.length - 1];
        
        if (lastStage) {
          await fetch(`${API_URL}/stages/${lastStage.id}/activate`, {
            method: 'PATCH'
          });
        }
        
        // Get final state
        const finalResponse = await fetch(`${API_URL}/stages`);
        const finalData = await finalResponse.json();
        setStages(finalData.data);
        
        // Update Extra Life status in the UI
        setExtraLifeStatus(true);
        
        toast.success('All stages and Extra Life have been reset', { id: loadingToast });
      }
    } catch (error) {
      toast.error('Failed to reset stages and Extra Life', { id: loadingToast });
    } finally {
      setIsResetDialogOpen(false);
    }
  };

  const handleDeleteStage = async (id: number) => {
    if (stages.length <= 1) {
      toast.error('Cannot delete stage: Minimum 1 stage required');
      return;
    }
  
    const loadingToast = toast.loading('Deleting stage...');
    try {
      const response = await fetch(`${API_URL}/stages/${id}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        const data = await response.json();
        const updatedStages = stages.filter(stage => stage.id !== id);
        setStages(updatedStages);
        toast.success('Stage deleted successfully', { id: loadingToast });
      } else {
        throw new Error('Failed to delete stage');
      }
    } catch (error) {
      toast.error('Failed to delete stage', { id: loadingToast });
    }
  };

  const handleAddStage = async (data: { prize: number; minimum_score: number }) => {


    const loadingToast = toast.loading('Adding new stage...');
    try {
      const response = await fetch(`${API_URL}/stages`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const newStage = await response.json();
        const updatedStages = [...stages, newStage.data];
        setStages(updatedStages);
        toast.success('Stage added successfully', { id: loadingToast });
      } else {
        throw new Error('Failed to add stage');
      }
    } catch (error) {
      toast.error('Failed to add stage', { id: loadingToast });
    } finally {
      setIsAddStageDialogOpen(false);
    }
  };

  const fetchExtraLifeStatus = async () => {
    try {
      const response = await fetch(`${API_URL}/extra-life`);
      const data = await response.json();
      setExtraLifeStatus(data.data.is_active);
    } catch (error) {
      console.error('Failed to fetch Extra Life status:', error);
      toast.error('Failed to fetch Extra Life status');
    }
  };

  useEffect(() => {
    fetchExtraLifeStatus();
  }, []);

  const toggleExtraLife = async () => {
    const loadingToast = toast.loading(`${extraLifeStatus ? 'Disabling' : 'Enabling'} Extra Life...`);
    try {
      const response = await fetch(`${API_URL}/extra-life/toggle`, {
        method: 'PATCH',
      });

      if (response.ok) {
        const data = await response.json();
        setExtraLifeStatus(data.data.is_active);
        toast.success(`Extra Life ${extraLifeStatus ? 'disabled' : 'enabled'} successfully`, { id: loadingToast });
      } else {
        throw new Error('Failed to toggle Extra Life');
      }
    } catch (error) {
      console.error('Error toggling Extra Life:', error);
      toast.error('Failed to toggle Extra Life', { id: loadingToast });
    }
  };

  const resetExtraLife = async () => {
    const loadingToast = toast.loading('Resetting Extra Life status...');
    try {
      const response = await fetch(`${API_URL}/extra-life/reset`, {
        method: 'POST',
      });

      if (response.ok) {
        await fetchExtraLifeStatus();
        toast.success('Extra Life reset successfully', { id: loadingToast });
      } else {
        throw new Error('Failed to reset Extra Life');
      }
    } catch (error) {
      toast.error('Failed to reset Extra Life', { id: loadingToast });
    }
  };

  if (loading) {
    return (
      <div className="p-6 bg-[#1A1C23] rounded-xl shadow-lg">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-700 rounded w-1/3 mb-8"></div>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-20 bg-gray-700 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
      <div className="max-w-8xl mx-auto">
        {/* Header */}
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50 mb-8">
          <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
          <div className="flex items-center gap-4">
            <div className="p-3 bg-violet-500/10 rounded-2xl ring-1 ring-violet-500/20">
              <Settings className="w-8 h-8 text-violet-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Prize Stage Settings
              </h1>
              <p className="text-gray-400 text-sm mt-1">
                Manage prize stages and extra life settings
              </p>
            </div>
            <div className="ml-auto flex space-x-4">
              <button
                onClick={() => setIsAddStageDialogOpen(true)}
                className="flex items-center px-6 py-3 bg-violet-600 text-white rounded-xl
                        hover:bg-violet-500 transition-all duration-200 transform hover:scale-105
                        active:scale-95 shadow-lg shadow-violet-500/20"
              >
                <Plus className="mr-2" size={18} />
                Add Stage
              </button>
              <button
                onClick={() => setIsResetDialogOpen(true)}
                className="flex items-center px-6 py-3 bg-red-600/90 text-white rounded-xl
                        hover:bg-red-500 transition-all duration-200 transform hover:scale-105
                        active:scale-95 shadow-lg shadow-red-500/20"
              >
                <RotateCcw className="mr-2" size={18} />
                Reset All
              </button>
            </div>
          </div>
        </div>

        {/* Two-Column Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Left Column - Controls and Extra Life */}
          <div className="space-y-8">
            {/* Extra Life Card */}
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
            >
              <div className="p-6 border-l-4 border-red-500">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                  <div className="p-3 bg-red-500/10 rounded-xl">
                      <Heart 
                        className={`${extraLifeStatus ? 'text-red-500' : 'text-gray-500'}`} 
                        size={24}
                        fill={extraLifeStatus ? 'currentColor' : 'none'}
                      />
                    </div>
                    <div className="ml-4">
                      <h2 className="text-white font-semibold text-lg">Extra Life</h2>
                      <p className="text-gray-400 text-sm">Give players an additional chance</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={toggleExtraLife}
                      className={`flex items-center px-6 py-3 rounded-xl transition-all duration-200
                        ${extraLifeStatus 
                          ? 'bg-red-500 hover:bg-red-600' 
                          : 'bg-emerald-500 hover:bg-emerald-600'
                        } text-white font-medium transform hover:scale-105 active:scale-95
                        shadow-lg ${extraLifeStatus ? 'shadow-red-500/20' : 'shadow-emerald-500/20'}`}
                    >
                      <Power className="mr-2" size={18} />
                      {extraLifeStatus ? 'Disable' : 'Enable'}
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>

            

            {/* Controls Grid */}
            <div className="grid grid-cols-1 gap-6">
              <ExtraLifeHideControl />
              <ConfettiControl />
            </div>
          </div>

          {/* Right Column - Stages List */}
          <div className="space-y-4">
            <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
              <h2 className="text-xl font-semibold text-white mb-6">Prize Stages</h2>
              <div className="space-y-4">
                <AnimatePresence>
                  {stages.map((stage) => (
                    <motion.div
                      key={stage.id}
                      layout
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl overflow-hidden"
                    >
                      <div className={`p-6 border-l-4 ${
                        stage.is_active ? 'border-violet-500' :
                        stage.is_unlocked ? 'border-emerald-500' : 'border-gray-600'
                      }`}>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <div className={`p-3 rounded-xl ${stage.is_unlocked ? 'bg-yellow-500/10' : 'bg-gray-500/10'}`}>
                              <Trophy 
                                className={`${!stage.is_unlocked ? 'text-gray-500' : 'text-yellow-500'}`} 
                                size={24} 
                              />
                            </div>
                            <span className="text-white font-semibold ml-4">Stage {stage.position}</span>
                          </div>
                          
                          <div className="flex items-center space-x-6">
                            {editingStage === stage.id ? (
                              <motion.div 
                                className="flex items-center"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                              >
                                <input
                                  type="number"
                                  value={editingValue}
                                  onChange={(e) => setEditingValue(e.target.value)}
                                  className="w-24 bg-black/20 text-white border border-violet-500/50 rounded-lg px-4 py-2 mr-3
                                           focus:outline-none focus:border-violet-500"
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      handlePrizeChange(stage.id);
                                    }
                                  }}
                                />
                                <button 
                                  onClick={() => handlePrizeChange(stage.id)}
                                  className="p-2 hover:bg-violet-500/20 rounded-lg transition-colors"
                                >
                                  <Save size={20} className="text-violet-400" />
                                </button>
                              </motion.div>
                            ) : (
                              <motion.div 
                                className="flex items-center"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                              >
                                <span className="text-lg font-medium text-white mr-3">{stage.prize}€</span>
                                <button 
                                  onClick={() => {
                                    setEditingStage(stage.id);
                                    setEditingValue(stage.prize.toString());
                                  }}
                                  className="p-2 hover:bg-violet-500/20 rounded-lg transition-colors"
                                >
                                  <Edit2 size={18} className="text-violet-400" />
                                </button>
                              </motion.div>
                            )}
                            
                            <div className="flex items-center space-x-4">
                              <button
                                onClick={() => toggleStageLock(stage.id, stage.is_unlocked)}
                                className="p-2 hover:bg-gray-700/50 rounded-lg transition-colors"
                                title={stage.is_unlocked ? 'Lock stage' : 'Unlock stage'}
                              >
                                {stage.is_unlocked ? 
                                  <Unlock size={20} className="text-emerald-500" /> :
                                  <Lock size={20} className="text-gray-500" />
                                }
                              </button>

                              <button
                                onClick={() => handleStageActivation(stage.id, !stage.is_active)}
                                className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                                  stage.is_active
                                    ? 'bg-emerald-500 text-white hover:bg-emerald-600'
                                    : 'bg-gray-700/50 text-gray-300 hover:bg-gray-700'
                                }`}
                              >
                                {stage.is_active ? 'Active' : 'Inactive'}
                              </button>

                              <button
  onClick={() => setStageToDelete(stage.id)}
  className="p-2 text-red-400 hover:bg-red-500/20 rounded-lg transition-colors"
  disabled={stages.length <= 1}
  title={stages.length <= 1 ? "Minimum 1 stage required" : "Delete stage"}
>
  <Trash2 size={20} />
</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>

        <Toaster position="top-right" toastOptions={{
          style: {
            background: '#333',
            color: '#fff',
          },
          duration: 3000,
        }} />

        <Dialog 
          isOpen={isResetDialogOpen}  
          onClose={() => setIsResetDialogOpen(false)}
          onConfirm={handleResetStages}
          title="Reset All Stages & Extra Life"
          message="Are you sure you want to reset all stages and Extra Life? This will reset both active states, unlock statuses, and enable Extra Life. This action cannot be undone."
        />

        <NewStageDialog
          isOpen={isAddStageDialogOpen}
          onClose={() => setIsAddStageDialogOpen(false)}
          onConfirm={handleAddStage}
          title="Add New Stage"
        />

        <DeleteDialog
          isOpen={stageToDelete !== null}
          onClose={() => setStageToDelete(null)}
          onConfirm={() => {
            if (stageToDelete !== null) {
              handleDeleteStage(stageToDelete);
              setStageToDelete(null);
            }
          }}
        />
      </div>
    </div>
  );
};

export default PrizeSettings;