import React, { useEffect, useState } from "react";
import {
  Calendar,
  Clock,
  Plus,
  Check,
  X,
  Edit2,
  Trash2,
  User,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import toast from "react-hot-toast";
import axios from "axios";
import { API_URL } from "components/api_config";
import { formatDateTime, getLocalDateTime, months, toUTC } from "./utils";
import { PaginationMeta, Task, TaskStats } from "./types";
import UserSelector from "./UserSelector";

export interface User {
  id: number;
  username: string;
  pfp: string | null;
  role: string;
}

const PlanningCalendar: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [stats, setStats] = useState<TaskStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);
  const [allTasks, setAllTasks] = useState<Task[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [filterType, setFilterType] = useState<string>("all");
  const [filterDate, setFilterDate] = useState<string>("all");
  const [filterUser, setFilterUser] = useState<string>("all");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortField, setSortField] = useState<string>("date");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const fetchAllTasks = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${API_URL}/planning?page=1&limit=10000`
      );
      const tasksWithAssignedTo = response.data.data.map((task: any) => {
        if (typeof task.assignedTo === "string" && task.assignedTo) {
          try {
            task.assignedTo = JSON.parse(task.assignedTo);
          } catch (e) {
            task.assignedTo = [];
          }
        }
        return task;
      });
      setAllTasks(tasksWithAssignedTo);
      setFilteredTasks(tasksWithAssignedTo);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching all tasks:", error);
      toast.error("Failed to fetch all events");
      setIsLoading(false);
    }
  };
  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    try {
      let result = [...allTasks].filter((task) => Boolean(task));

      if (filterType !== "all") {
        result = result.filter((task) => task && task.type === filterType);
      }

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const nextWeek = new Date(today);
      nextWeek.setDate(nextWeek.getDate() + 7);

      const thisMonth = new Date(today);
      thisMonth.setMonth(thisMonth.getMonth() + 1);

      if (filterDate === "today") {
        result = result.filter((task) => {
          if (!task || !task.date) return false;
          try {
            const taskDate = new Date(task.date);
            return (
              taskDate.getDate() === today.getDate() &&
              taskDate.getMonth() === today.getMonth() &&
              taskDate.getFullYear() === today.getFullYear()
            );
          } catch (e) {
            return false;
          }
        });
      } else if (filterDate === "tomorrow") {
      }

      if (filterUser !== "all") {
        result = result.filter((task) => {
          if (!task) return false;

          try {
            if (task.user && task.user.username === filterUser) return true;
          } catch (e) {
            console.error("Error checking task creator:", e);
          }

          try {
            if (task.assignedTo) {
              if (Array.isArray(task.assignedTo)) {
                return task.assignedTo.includes(filterUser);
              } else if (typeof task.assignedTo === "string") {
                try {
                  const assigned = JSON.parse(task.assignedTo);
                  return (
                    Array.isArray(assigned) && assigned.includes(filterUser)
                  );
                } catch (e) {
                  return false;
                }
              }
            }
          } catch (e) {
            console.error("Error checking task assignments:", e);
          }
          return false;
        });
      }

      result.sort((a, b) => {
        if (!a || !b) return 0;

        try {
          if (sortField === "date") {
            if (!a.date || !b.date) return 0;
            return sortDirection === "asc"
              ? new Date(a.date).getTime() - new Date(b.date).getTime()
              : new Date(b.date).getTime() - new Date(a.date).getTime();
          } else if (sortField === "title") {
            if (!a.title || !b.title) return 0;
            return sortDirection === "asc"
              ? a.title.localeCompare(b.title)
              : b.title.localeCompare(a.title);
          } else if (sortField === "type") {
            if (!a.type || !b.type) return 0;
            return sortDirection === "asc"
              ? a.type.localeCompare(b.type)
              : b.type.localeCompare(a.type);
          } else if (sortField === "creator") {
            const aUsername = a.user && a.user.username ? a.user.username : "";
            const bUsername = b.user && b.user.username ? b.user.username : "";
            return sortDirection === "asc"
              ? aUsername.localeCompare(bUsername)
              : bUsername.localeCompare(aUsername);
          }
        } catch (e) {
          console.error("Error during sort:", e);
        }
        return 0;
      });

      setFilteredTasks(result);
    } catch (error) {
      console.error("Error filtering tasks:", error);
      setFilteredTasks([]);
    }
  }, [
    filterType,
    filterDate,
    filterUser,
    searchTerm,
    allTasks,
    sortField,
    sortDirection,
  ]);

  useEffect(() => {
    fetchAllTasks();
  }, []);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [showAddTask, setShowAddTask] = useState(false);
  const [newTask, setNewTask] = useState<{
    title: string;
    description: string;
    type: "task" | "idea" | "stream";
    date: string;
    time: string;
    assignedTo: string[];
  }>({
    title: "",
    description: "",
    type: "task",
    date: selectedDate.toISOString().slice(0, 19).replace("T", " "),
    time: "00:00",
    assignedTo: [],
  });
  const [editingTask, setEditingTask] = useState<Task | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/all-profile`);
        setUsers(response.data);
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, []);

  const handleEditTask = async (taskId: number, updates: Partial<Task>) => {
    try {
      let safeAssignedTo = updates.assignedTo;

      if (updates.assignedTo) {
        if (typeof updates.assignedTo === "string") {
          try {
            safeAssignedTo = JSON.parse(updates.assignedTo);
          } catch (e) {
            safeAssignedTo = [];
          }
        }

        if (!Array.isArray(safeAssignedTo)) {
          safeAssignedTo = [];
        }
      } else {
        safeAssignedTo = [];
      }

      const safeUpdates = {
        ...updates,
        title: updates.title || "",
        description: updates.description || "",
        type: updates.type || "task",
        date: updates.date || new Date().toISOString(),
        assignedTo: safeAssignedTo,
      };

      const response = await axios.put(
        `${API_URL}/planning/${taskId}`,
        safeUpdates
      );

      const updatedTask = response.data.data;

      setTasks(tasks.map((task) => (task.id === taskId ? updatedTask : task)));
      setAllTasks(
        allTasks.map((task) => (task.id === taskId ? updatedTask : task))
      );
      setFilteredTasks(
        filteredTasks.map((task) => (task.id === taskId ? updatedTask : task))
      );

      setEditingTask(null);
      toast.success("Task updated successfully");
      fetchStats();
    } catch (error) {
      console.error("Failed to update task:", error);
      toast.error("Failed to update task");
    }
  };

  useEffect(() => {
    setNewTask((prev) => ({
      ...prev,
      date: selectedDate.toISOString().slice(0, 19).replace("T", " "),
    }));
  }, [selectedDate]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/planning?page=${page}&limit=10`
      );
      const tasksWithAssignedTo = response.data.data.map((task: any) => ({
        ...task,
        assignedTo: JSON.parse(task.assignedTo),
      }));
      setTasks(tasksWithAssignedTo);
      setMeta(response.data.meta);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Failed to fetch tasks");
    }
  };

  const fetchStats = async () => {
    try {
      const response = await axios.get(API_URL + "/planning/stats");
      setStats(response.data.data);
    } catch (error) {
      toast.error("Failed to fetch stats");
    }
  };

  const getTaskCountStyle = (count: number) => {
    if (count === 0) return "";
    if (count <= 2) return "bg-blue-500/10";
    if (count <= 4) return "bg-blue-500/20";
    return "bg-blue-500/30";
  };

  const getTaskCountForDate = (date: Date) => {
    return tasks.filter(
      (task) => new Date(task.date).toDateString() === date.toDateString()
    ).length;
  };

  const addTask = async () => {
    if (!newTask.title.trim()) return;

    try {
      const taskDate = new Date(selectedDate);
      const [hours, minutes] = newTask.time.split(":");
      taskDate.setHours(parseInt(hours), parseInt(minutes));

      const taskToAdd = {
        ...newTask,
        date: toUTC(taskDate),
        assignedTo: selectedUsers,
      };

      const response = await axios.post(API_URL + "/planning", taskToAdd);
      setSelectedUsers([]);

      await fetchTasks();

      await fetchAllTasks();

      await fetchStats();

      setShowAddTask(false);
      toast.success("Task added successfully");
    } catch (error) {
      toast.error("Failed to add task");
    }
  };

  const deleteTask = async (taskId: number) => {
    try {
      await axios.delete(`${API_URL}/planning/${taskId}`);

      setTasks(tasks.filter((task) => task.id !== taskId));
      setAllTasks(allTasks.filter((task) => task.id !== taskId));
      setFilteredTasks(filteredTasks.filter((task) => task.id !== taskId));

      toast.success("Task deleted successfully");
      fetchStats();
    } catch (error) {
      toast.error("Failed to delete task");
    }
  };

  const updateTask = async (taskId: number, updates: Partial<Task>) => {
    try {
      const response = await axios.put(
        `${API_URL}/planning/${taskId}`,
        updates
      );

      const updatedTask = response.data.data;

      setTasks(tasks.map((task) => (task.id === taskId ? updatedTask : task)));
      setAllTasks(
        allTasks.map((task) => (task.id === taskId ? updatedTask : task))
      );
      setFilteredTasks(
        filteredTasks.map((task) => (task.id === taskId ? updatedTask : task))
      );

      toast.success("Task updated successfully");
      fetchStats();
    } catch (error) {
      toast.error("Failed to update task");
    }
  };

  const getDaysInMonth = (date: Date): number => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0).getDate();
  };

  const getMonthDays = (): (number | null)[] => {
    const days: (number | null)[] = [];
    const firstDay = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1
    ).getDay();
    const daysInMonth = getDaysInMonth(selectedDate);

    const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;

    for (let i = 0; i < adjustedFirstDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    return days;
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const previousMonth = (): void => {
    setSelectedDate(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1)
    );
  };

  const nextMonth = (): void => {
    setSelectedDate(
      new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1)
    );
  };
  useEffect(() => {
    fetchTasks();
    fetchStats();
  }, [page]);

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
      <style>
        {`
          .glass-card {
            background: rgba(17, 17, 23, 0.7);
            backdrop-filter: blur(16px);
            border: 1px solid rgba(255, 255, 255, 0.05);
            box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
          }
          
          .calendar-day {
            transition: all 0.3s ease;
          }
          
          .calendar-day:hover {
            transform: translateY(-2px);
            box-shadow: 0 5px 15px rgba(124, 58, 237, 0.2);
          }
          
          .task-card {
            border-left: 3px solid transparent;
            transition: all 0.2s ease;
          }
          
          .task-card:hover {
            transform: translateX(2px);
          }
          
          .task-card-stream {
            border-left-color: #8B5CF6;
          }
          
          .task-card-task {
            border-left-color: #3B82F6;
          }
          
          .task-card-idea {
            border-left-color: #10B981;
          }

          .scrollbar-custom {
            scrollbar-width: thin;
            scrollbar-color: rgba(139, 92, 246, 0.3) rgba(0, 0, 0, 0.2);
          }
          
          .scrollbar-custom::-webkit-scrollbar {
            width: 6px;
          }
          
          .scrollbar-custom::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 3px;
          }
          
          .scrollbar-custom::-webkit-scrollbar-thumb {
            background-color: rgba(139, 92, 246, 0.3);
            border-radius: 3px;
            transition: background-color 0.2s;
          }
          
          .scrollbar-custom::-webkit-scrollbar-thumb:hover {
            background-color: rgba(139, 92, 246, 0.5);
          }
        `}
      </style>

      <div className="max-w-7xl mx-auto">
        {/* Header with improved styling */}
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50 mb-8">
          <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
          <div className="absolute -top-24 -right-24 w-48 h-48 bg-violet-500/10 rounded-full blur-3xl"></div>
          <div className="absolute -bottom-8 -left-8 w-40 h-40 bg-blue-500/10 rounded-full blur-2xl"></div>

          <div className="flex items-center gap-4">
            <div className="p-4 bg-violet-500/10 rounded-2xl ring-1 ring-violet-500/20 shadow-lg shadow-violet-500/5">
              <Calendar className="w-8 h-8 text-violet-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-gray-300">
                Planning Calendar
              </h1>
              <p className="text-gray-400 text-sm mt-1">
                Manage your Streams, Tasks and Ideas in one place
              </p>
            </div>

            {stats && (
              <div className="ml-auto grid grid-cols-4 gap-4">
                <div className="bg-black/20 rounded-lg p-3 text-center">
                  <div className="text-violet-400 text-xl font-bold">
                    {stats.total}
                  </div>
                  <div className="text-gray-400 text-xs">Total</div>
                </div>
                <div className="bg-black/20 rounded-lg p-3 text-center">
                  <div className="text-blue-400 text-xl font-bold">
                    {stats.tasks}
                  </div>
                  <div className="text-gray-400 text-xs">Tasks</div>
                </div>

                <div className="bg-black/20 rounded-lg p-3 text-center">
                  <div className="text-green-400 text-xl font-bold">
                    {stats.ideas || 0}
                  </div>
                  <div className="text-gray-400 text-xs">Ideas</div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Main Content with improved grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Calendar - Left side (spans 2 columns) */}
          <div className="lg:col-span-2">
            <div className="glass-card rounded-xl p-6">
              <div className="flex justify-between items-center mb-6">
                <div className="flex items-center gap-4">
                  <select
                    value={selectedDate.getMonth()}
                    onChange={(e) => {
                      const newDate = new Date(selectedDate);
                      newDate.setMonth(parseInt(e.target.value));
                      setSelectedDate(newDate);
                    }}
                    className="bg-gray-800/50 rounded-lg px-4 py-2 text-white border border-gray-700 focus:border-violet-500 focus:ring-1 focus:ring-violet-500 focus:outline-none"
                  >
                    {months.map((month, index) => (
                      <option key={month} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>

                  <select
                    value={selectedDate.getFullYear()}
                    onChange={(e) => {
                      const newDate = new Date(selectedDate);
                      newDate.setFullYear(parseInt(e.target.value));
                      setSelectedDate(newDate);
                    }}
                    className="bg-gray-800/50 rounded-lg px-4 py-2 text-white border border-gray-700 focus:border-violet-500 focus:ring-1 focus:ring-violet-500 focus:outline-none"
                  >
                    {Array.from(
                      { length: 5 },
                      (_, i) => new Date().getFullYear() - 2 + i
                    ).map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex gap-2">
                  <motion.button
                    onClick={previousMonth}
                    className="p-2 hover:bg-violet-500/20 rounded-lg text-white border border-gray-700/50"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    ←
                  </motion.button>
                  <motion.button
                    onClick={nextMonth}
                    className="p-2 hover:bg-violet-500/20 rounded-lg text-white border border-gray-700/50"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    →
                  </motion.button>
                </div>
              </div>

              <div className="grid grid-cols-7 gap-2 mb-2">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(
                  (day) => (
                    <div
                      key={day}
                      className="text-center text-gray-400 text-sm font-medium py-1"
                    >
                      {day}
                    </div>
                  )
                )}
              </div>

              <div className="grid grid-cols-7 gap-2">
                {getMonthDays().map((day, index) => (
                  <motion.div key={index} className="aspect-square">
                    {day ? (
                      <motion.div
                        onClick={() =>
                          setSelectedDate(
                            new Date(
                              selectedDate.getFullYear(),
                              selectedDate.getMonth(),
                              day
                            )
                          )
                        }
                        className={`calendar-day w-full h-full min-h-[80px] p-2 rounded-lg cursor-pointer bg-gray-800/30 relative
                      ${getTaskCountStyle(
                        getTaskCountForDate(
                          new Date(
                            selectedDate.getFullYear(),
                            selectedDate.getMonth(),
                            day
                          )
                        )
                      )}
                      ${
                        selectedDate.getDate() === day &&
                        selectedDate.getMonth() === selectedDate.getMonth() &&
                        selectedDate.getFullYear() ===
                          selectedDate.getFullYear()
                          ? "ring-2 ring-violet-500 shadow-lg shadow-violet-500/20"
                          : ""
                      }
                      ${
                        new Date().getDate() === day &&
                        new Date().getMonth() === selectedDate.getMonth() &&
                        new Date().getFullYear() === selectedDate.getFullYear()
                          ? "border border-violet-500"
                          : ""
                      }`}
                        whileHover={{ scale: 1.02, y: -2 }}
                      >
                        <div className="flex justify-between items-start">
                          <span className="text-gray-400 text-sm font-medium">
                            {day}
                          </span>

                          {/* Task count indicator - Nouvelle structure */}
                          {getTaskCountForDate(
                            new Date(
                              selectedDate.getFullYear(),
                              selectedDate.getMonth(),
                              day
                            )
                          ) > 0 && (
                            <span
                              className="flex items-center justify-center w-5 h-5 text-xs 
                          font-medium text-white bg-violet-500 rounded-full shadow-sm shadow-violet-500/20"
                            >
                              {getTaskCountForDate(
                                new Date(
                                  selectedDate.getFullYear(),
                                  selectedDate.getMonth(),
                                  day
                                )
                              )}
                            </span>
                          )}
                        </div>

                        {/* Tasks preview */}
                        {tasks
                          .filter((task) => {
                            const taskDate = getLocalDateTime(task.date);
                            return (
                              taskDate.getDate() === day &&
                              taskDate.getMonth() === selectedDate.getMonth() &&
                              taskDate.getFullYear() ===
                                selectedDate.getFullYear()
                            );
                          })
                          .slice(0, 2)
                          .map((task) => (
                            <div
                              key={task.id}
                              className={`mt-1 text-xs px-2 py-1 rounded-md truncate
                                ${
                                  task.type === "stream"
                                    ? "bg-purple-500/10 text-purple-300"
                                    : task.type === "task"
                                    ? "bg-blue-500/10 text-blue-300"
                                    : "bg-green-500/10 text-green-300"
                                }`}
                            >
                              {formatDateTime(task.date).time} - {task.title}
                            </div>
                          ))}

                        {/* More indicator */}
                        {tasks.filter((task) => {
                          const taskDate = getLocalDateTime(task.date);
                          return (
                            taskDate.getDate() === day &&
                            taskDate.getMonth() === selectedDate.getMonth() &&
                            taskDate.getFullYear() ===
                              selectedDate.getFullYear()
                          );
                        }).length > 2 && (
                          <div className="text-xs text-gray-500 mt-1 text-center">
                            +{" "}
                            {tasks.filter((task) => {
                              const taskDate = getLocalDateTime(task.date);
                              return (
                                taskDate.getDate() === day &&
                                taskDate.getMonth() ===
                                  selectedDate.getMonth() &&
                                taskDate.getFullYear() ===
                                  selectedDate.getFullYear()
                              );
                            }).length - 2}{" "}
                            more
                          </div>
                        )}
                      </motion.div>
                    ) : (
                      <div className="w-full h-full rounded-lg bg-transparent"></div>
                    )}
                  </motion.div>
                ))}
              </div>
            </div>
          </div>

          {/* Tasks Panel - Right side */}
          <div className="glass-card rounded-xl p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl text-white font-semibold flex items-center">
                <span className="p-1.5 bg-violet-500/20 rounded-lg mr-2">
                  <Calendar className="w-5 h-5 text-violet-400" />
                </span>
                {selectedDate.getDate()} {months[selectedDate.getMonth()]} Tasks
              </h2>
              <motion.button
                onClick={() => setShowAddTask(true)}
                className="p-2 bg-violet-500/20 rounded-lg text-violet-400 hover:bg-violet-500/30 transition-colors shadow-sm shadow-violet-500/10"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Plus size={20} />
              </motion.button>
            </div>
            <AnimatePresence>
              {editingTask && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center p-4 bg-black/70 backdrop-blur-sm"
                  onClick={() => setEditingTask(null)}
                >
                  <motion.div
                    initial={{ scale: 0.95, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.95, opacity: 0 }}
                    className="bg-gray-900/90 backdrop-blur-md rounded-xl p-5 w-full max-w-md border border-gray-800 shadow-lg shadow-violet-500/10 my-8 mx-auto"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex justify-between items-center mb-6">
                      <h3 className="text-lg font-medium text-white flex items-center gap-2">
                        <Edit2 className="w-5 h-5 text-violet-400" />
                        Edit Task
                      </h3>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => setEditingTask(null)}
                        className="text-gray-400 hover:text-white"
                      >
                        <X size={20} />
                      </motion.button>
                    </div>

                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Title
                        </label>
                        <input
                          type="text"
                          value={editingTask.title}
                          onChange={(e) =>
                            setEditingTask({
                              ...editingTask,
                              title: e.target.value,
                            })
                          }
                          className="w-full bg-gray-800/50 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Description
                        </label>
                        <textarea
                          value={editingTask.description}
                          onChange={(e) =>
                            setEditingTask({
                              ...editingTask,
                              description: e.target.value,
                            })
                          }
                          className="w-full bg-gray-800/50 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700 min-h-[100px]"
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-3">
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">
                            Type
                          </label>
                          <select
                            value={editingTask.type}
                            onChange={(e) =>
                              setEditingTask({
                                ...editingTask,
                                type: e.target.value as
                                  | "task"
                                  | "idea"
                                  | "stream",
                              })
                            }
                            className="w-full bg-gray-800/50 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700"
                          >
                            <option value="task">Task</option>
                            <option value="idea">Idea</option>
                            <option value="stream">Stream</option>
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-400 mb-1">
                            Time
                          </label>
                          <input
                            type="time"
                            value={formatDateTime(editingTask.date).time}
                            onChange={(e) => {
                              const [hours, minutes] =
                                e.target.value.split(":");
                              const newDate = new Date(editingTask.date);
                              newDate.setHours(
                                parseInt(hours),
                                parseInt(minutes)
                              );
                              setEditingTask({
                                ...editingTask,
                                date: newDate.toISOString(),
                              });
                            }}
                            className="w-full bg-gray-800/50 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700"
                          />
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-1">
                          Date
                        </label>
                        <input
                          type="date"
                          value={formatDateTime(editingTask.date).date}
                          onChange={(e) => {
                            const dateComponents = e.target.value.split("-");
                            const timeComponents = formatDateTime(
                              editingTask.date
                            ).time.split(":");

                            const newDate = new Date(
                              parseInt(dateComponents[0]),
                              parseInt(dateComponents[1]) - 1,
                              parseInt(dateComponents[2]),
                              parseInt(timeComponents[0]),
                              parseInt(timeComponents[1])
                            );

                            setEditingTask({
                              ...editingTask,
                              date: newDate.toISOString(),
                            });
                          }}
                          className="w-full bg-gray-800/50 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700"
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-400 mb-2">
                          Assigned Users
                        </label>
                        <UserSelector
                          users={users.filter((u) => u && u.username)}
                          selectedUsers={(() => {
                            try {
                              if (!editingTask.assignedTo) return [];
                              if (typeof editingTask.assignedTo === "string") {
                                const parsed = JSON.parse(
                                  editingTask.assignedTo || "[]"
                                );
                                return Array.isArray(parsed) ? parsed : [];
                              }
                              return Array.isArray(editingTask.assignedTo)
                                ? editingTask.assignedTo
                                : [];
                            } catch (e) {
                              console.error("Error parsing assignedTo:", e);
                              return [];
                            }
                          })()}
                          onChange={(selectedUsers) => {
                            setEditingTask({
                              ...editingTask,
                              assignedTo: selectedUsers,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex justify-end space-x-3 mt-6">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => setEditingTask(null)}
                        className="px-4 py-2 bg-gray-800 text-gray-300 rounded-lg hover:bg-gray-700"
                      >
                        Cancel
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => {
                          handleEditTask(editingTask.id, {
                            title: editingTask.title,
                            description: editingTask.description,
                            type: editingTask.type,
                            date: toUTC(new Date(editingTask.date)),
                            assignedTo: editingTask.assignedTo,
                          });
                        }}
                        className="px-4 py-2 bg-violet-600 text-white rounded-lg shadow-md shadow-violet-500/20 hover:bg-violet-500"
                      >
                        Save Changes
                      </motion.button>
                    </div>
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {showAddTask && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="mb-6 bg-black/20 rounded-lg p-5 border border-gray-800"
                >
                  <h3 className="text-lg text-white mb-4 font-medium">
                    New Task
                  </h3>
                  <input
                    type="text"
                    placeholder="Title"
                    className="w-full bg-gray-800/50 rounded-lg p-3 mb-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700"
                    value={newTask.title}
                    onChange={(e) =>
                      setNewTask({ ...newTask, title: e.target.value })
                    }
                  />
                  <textarea
                    placeholder="Description"
                    className="w-full bg-gray-800/50 rounded-lg p-3 mb-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700 min-h-[100px]"
                    value={newTask.description}
                    onChange={(e) =>
                      setNewTask({ ...newTask, description: e.target.value })
                    }
                  />

                  <div className="grid grid-cols-2 gap-3 mb-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">
                        Type
                      </label>
                      <select
                        className="w-full bg-gray-800/50 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700"
                        value={newTask.type}
                        onChange={(e) =>
                          setNewTask({
                            ...newTask,
                            type: e.target.value as "task" | "idea" | "stream",
                          })
                        }
                      >
                        <option value="task">Task</option>
                        <option value="idea">Idea</option>
                        <option value="stream">Stream</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-400 mb-1">
                        Time
                      </label>
                      <input
                        type="time"
                        className="w-full bg-gray-800/50 rounded-lg p-3 text-white focus:outline-none focus:ring-2 focus:ring-violet-500 border border-gray-700 appearance-none"
                        value={newTask.time}
                        onChange={(e) =>
                          setNewTask({
                            ...newTask,
                            time: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <UserSelector
                    users={users.filter((u) => u && u.username)}
                    selectedUsers={selectedUsers}
                    onChange={(selected) => {
                      const safeSelected = Array.isArray(selected)
                        ? selected
                        : [];
                      setSelectedUsers(safeSelected);
                    }}
                  />

                  <div className="flex justify-end gap-2 mt-4">
                    <motion.button
                      onClick={() => setShowAddTask(false)}
                      className="px-4 py-2 rounded-lg text-red-400 hover:bg-red-500/10"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Cancel
                    </motion.button>
                    <motion.button
                      onClick={addTask}
                      className="px-4 py-2 bg-violet-500 hover:bg-violet-600 text-white rounded-lg shadow-md shadow-violet-500/20"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      disabled={!newTask.title.trim()}
                    >
                      Add Task
                    </motion.button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            <div className="space-y-3 max-h-[520px] overflow-y-auto scrollbar-custom pr-1">
              <AnimatePresence>
                {tasks
                  .filter((task) => {
                    const taskDate = new Date(task.date);
                    return (
                      taskDate.getDate() === selectedDate.getDate() &&
                      taskDate.getMonth() === selectedDate.getMonth() &&
                      taskDate.getFullYear() === selectedDate.getFullYear()
                    );
                  })
                  .map((task) => (
                    <motion.div
                      key={task.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      className={`task-card bg-black/20 rounded-lg p-4 border border-gray-800/50
                        ${
                          task.type === "stream"
                            ? "task-card-stream"
                            : task.type === "task"
                            ? "task-card-task"
                            : "task-card-idea"
                        }`}
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="text-white font-medium group flex items-center">
                            {task.title}
                            <span
                              className={`ml-2 text-xs px-2 py-0.5 rounded-full
                              ${
                                task.type === "stream"
                                  ? "bg-purple-500/20 text-purple-400"
                                  : task.type === "task"
                                  ? "bg-blue-500/20 text-blue-400"
                                  : "bg-green-500/20 text-green-400"
                              }`}
                            >
                              {task.type}
                            </span>
                          </h3>

                          <div className="flex items-center gap-2 text-gray-400 text-sm mt-2">
                            <Clock size={14} className="text-violet-400" />
                            <span>{formatDateTime(task.date).time}</span>
                          </div>

                          <p className="text-gray-400 text-sm mt-2 line-clamp-2">
                            {task.description}
                          </p>

                          {task.assignedTo && task.assignedTo.length > 0 && (
                            <div className="flex flex-wrap gap-1 mt-3">
                              {task.assignedTo.map((username: string) => (
                                <span
                                  key={username}
                                  className="px-2 py-1 text-xs rounded-full bg-blue-500/10 text-blue-400 flex items-center gap-1"
                                >
                                  <User size={10} />
                                  {username}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="flex gap-1">
                          <motion.button
                            onClick={() => deleteTask(task.id)}
                            className="p-1.5 hover:bg-red-500/20 rounded-lg text-red-400"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Trash2 size={14} />
                          </motion.button>
                          <motion.button
                            onClick={() => setEditingTask(task)}
                            className="p-1.5 hover:bg-violet-500/20 rounded-lg text-violet-400"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                          >
                            <Edit2 size={14} />
                          </motion.button>
                        </div>
                      </div>
                    </motion.div>
                  ))}
              </AnimatePresence>

              {tasks.filter((task) => {
                const taskDate = new Date(task.date);
                return (
                  taskDate.getDate() === selectedDate.getDate() &&
                  taskDate.getMonth() === selectedDate.getMonth() &&
                  taskDate.getFullYear() === selectedDate.getFullYear()
                );
              }).length === 0 && (
                <div className="flex flex-col items-center justify-center py-10 text-gray-500">
                  <Calendar className="w-10 h-10 mb-2 opacity-30" />
                  <p>No tasks for this day</p>
                  <motion.button
                    onClick={() => setShowAddTask(true)}
                    className="mt-3 px-4 py-2 bg-violet-500/20 text-violet-400 rounded-lg hover:bg-violet-500/30"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Add a task
                  </motion.button>
                </div>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="glass-card rounded-xl p-6 w-full">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
            <div className="flex items-center gap-2">
              <div className="p-2 bg-violet-500/10 rounded-xl">
                <Calendar className="w-6 h-6 text-violet-400" />
              </div>
              <h2 className="text-xl font-semibold text-white">
                Upcoming Events
              </h2>
            </div>

            {/* Filters Section */}
            <div className="flex flex-wrap gap-3 w-full md:w-auto">
              <select
                className="bg-gray-800/50 rounded-lg px-3 py-2 text-white border border-gray-700 focus:border-violet-500 focus:ring-1 focus:ring-violet-500 focus:outline-none text-sm"
                onChange={(e) => setFilterType(e.target.value)}
                value={filterType}
              >
                <option value="all">All Types</option>
                <option value="stream">Streams</option>
                <option value="task">Tasks</option>
                <option value="idea">Ideas</option>
              </select>

              <select
                className="bg-gray-800/50 rounded-lg px-3 py-2 text-white border border-gray-700 focus:border-violet-500 focus:ring-1 focus:ring-violet-500 focus:outline-none text-sm"
                onChange={(e) => setFilterDate(e.target.value)}
                value={filterDate}
              >
                <option value="all">All Dates</option>
                <option value="today">Today</option>
                <option value="tomorrow">Tomorrow</option>
                <option value="thisWeek">This Week</option>
                <option value="nextWeek">Next Week</option>
                <option value="thisMonth">This Month</option>
              </select>

              <select
                className="bg-gray-800/50 rounded-lg px-3 py-2 text-white border border-gray-700 focus:border-violet-500 focus:ring-1 focus:ring-violet-500 focus:outline-none text-sm"
                onChange={(e) => setFilterUser(e.target.value)}
                value={filterUser}
              >
                <option value="all">All Users</option>
                {users
                  .filter((user) => user && user.username)
                  .map((user) => (
                    <option key={user.id} value={user.username}>
                      {user.username}
                    </option>
                  ))}
              </select>

              <input
                type="text"
                placeholder="Search..."
                className="bg-gray-800/50 rounded-lg px-3 py-2 text-white border border-gray-700 focus:border-violet-500 focus:ring-1 focus:ring-violet-500 focus:outline-none text-sm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <motion.button
                onClick={() => {
                  setFilterType("all");
                  setFilterDate("all");
                  setFilterUser("all");
                  setSearchTerm("");
                  fetchAllTasks();
                }}
                className="px-3 py-2 bg-gray-800/50 rounded-lg text-violet-400 hover:bg-violet-500/20 border border-gray-700 text-sm"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Reset Filters
              </motion.button>
            </div>
          </div>

          <div className="overflow-hidden rounded-xl border border-gray-800/50">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-black/30">
                    <th
                      className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer hover:text-violet-400"
                      onClick={() => handleSort("date")}
                    >
                      Date & Time{" "}
                      {sortField === "date" &&
                        (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer hover:text-violet-400"
                      onClick={() => handleSort("title")}
                    >
                      Title{" "}
                      {sortField === "title" &&
                        (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer hover:text-violet-400"
                      onClick={() => handleSort("type")}
                    >
                      Type{" "}
                      {sortField === "type" &&
                        (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Assigned To
                    </th>
                    <th
                      className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider cursor-pointer hover:text-violet-400"
                      onClick={() => handleSort("creator")}
                    >
                      Created By{" "}
                      {sortField === "creator" &&
                        (sortDirection === "asc" ? "↑" : "↓")}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-800/50">
                  {filteredTasks.length > 0 ? (
                    filteredTasks.map((task) => (
                      <tr
                        key={task.id}
                        className={`hover:bg-violet-500/5 transition-colors ${
                          new Date(task.date).toDateString() ===
                          new Date().toDateString()
                            ? "bg-violet-500/10"
                            : ""
                        }`}
                      >
                        <td className="px-4 py-4 whitespace-nowrap">
                          <div className="flex flex-col">
                            <span className="text-white font-medium">
                              {new Date(task.date).toLocaleDateString("en-US", {
                                weekday: "short",
                                day: "numeric",
                                month: "short",
                              })}
                            </span>
                            <span className="text-gray-400 text-sm">
                              {formatDateTime(task.date).time}
                            </span>
                          </div>
                        </td>
                        <td className="px-4 py-4">
                          <div className="flex items-center">
                            <div
                              className={`w-2 h-2 rounded-full mr-2 ${
                                task.type === "stream"
                                  ? "bg-purple-400"
                                  : task.type === "task"
                                  ? "bg-blue-400"
                                  : "bg-green-400"
                              }`}
                            ></div>
                            <div>
                              <span className="text-white">{task.title}</span>
                              {task.description && (
                                <p className="text-gray-400 text-sm mt-1 line-clamp-2">
                                  {task.description}
                                </p>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-4">
                          <span
                            className={`px-2 py-1 text-xs rounded-full ${
                              task.type === "stream"
                                ? "bg-purple-500/20 text-purple-400"
                                : task.type === "task"
                                ? "bg-blue-500/20 text-blue-400"
                                : "bg-green-500/20 text-green-400"
                            }`}
                          >
                            {task.type}
                          </span>
                        </td>
                        <td className="px-4 py-4">
                          <div className="flex flex-wrap gap-1">
                            {(() => {
                              try {
                                if (!task.assignedTo) {
                                  return (
                                    <span className="text-gray-500 text-xs">
                                      Not assigned
                                    </span>
                                  );
                                }

                                let assignedUsers = task.assignedTo;
                                if (typeof task.assignedTo === "string") {
                                  try {
                                    assignedUsers = JSON.parse(task.assignedTo);
                                    if (!Array.isArray(assignedUsers)) {
                                      assignedUsers = [];
                                    }
                                  } catch (e) {
                                    assignedUsers = [];
                                  }
                                }
                                if (
                                  !Array.isArray(assignedUsers) ||
                                  assignedUsers.length === 0
                                ) {
                                  return (
                                    <span className="text-gray-500 text-xs">
                                      Not assigned
                                    </span>
                                  );
                                }

                                return assignedUsers.map((username, i) => (
                                  <span
                                    key={`${task.id}-${
                                      username || "unknown"
                                    }-${i}`}
                                    className="px-2 py-0.5 text-xs rounded-full bg-blue-500/10 text-blue-400 flex items-center gap-1"
                                  >
                                    <User size={10} />
                                    {username || "Unknown User"}
                                  </span>
                                ));
                              } catch (error) {
                                console.error(
                                  "Error rendering assigned users:",
                                  error
                                );
                                return (
                                  <span className="text-gray-500 text-xs">
                                    Error loading assignments
                                  </span>
                                );
                              }
                            })()}
                          </div>
                        </td>
                        <td className="px-4 py-4 text-sm text-gray-400">
                          {task.user && task.user.username
                            ? task.user.username
                            : "Unknown"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={6}
                        className="px-4 py-8 text-center text-gray-500"
                      >
                        <div className="flex flex-col items-center">
                          <Calendar className="w-8 h-8 mb-2 opacity-30" />
                          <p>No events found matching your filters</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningCalendar;
