// Modifiez le code pour maintenir l'opacité complète lorsque les utilisateurs sont sélectionnés
import { AnimatePresence, motion } from "framer-motion";
import { Check, User } from "lucide-react";
import { useState, useEffect, useRef } from "react";

export interface User {
  id: number;
  username: string;
  pfp: string | null;
  role: string;
}

const UserSelector: React.FC<{
  users: User[];
  selectedUsers: string[] | number[] | null | undefined;
  onChange: (values: string[]) => void;
}> = ({ users, selectedUsers = [], onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const safeUsers = Array.isArray(users)
    ? users.filter((u) => u && u.username)
    : [];

  const normalizedSelectedUsers = Array.isArray(selectedUsers)
    ? selectedUsers
    : [];

  const userExists = (user: User | undefined): user is User => {
    return user !== undefined && user !== null && user.username !== undefined;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

 
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const isUserSelected = (user: User): boolean => {
    if (!user || !user.username) return false;

    if (normalizedSelectedUsers.length === 0) return false;

    if (typeof normalizedSelectedUsers[0] === "number") {
      return (normalizedSelectedUsers as number[]).includes(user.id);
    } else {
      return (normalizedSelectedUsers as string[]).includes(user.username);
    }
  };

  const toggleUser = (user: User) => {
    if (!user || !user.username) return;

    if (
      normalizedSelectedUsers.length > 0 &&
      typeof normalizedSelectedUsers[0] === "number"
    ) {
      const updatedSelection = isUserSelected(user)
        ? (normalizedSelectedUsers as number[]).filter((id) => id !== user.id)
        : [...(normalizedSelectedUsers as number[]), user.id];

      const usernamesFromIds = updatedSelection
        .map((id) => safeUsers.find((u) => u.id === id))
        .filter(userExists)
        .map((u) => u.username);

      onChange(usernamesFromIds);
    } else {
      const updatedSelection = isUserSelected(user)
        ? (normalizedSelectedUsers as string[]).filter(
            (name) => name !== user.username
          )
        : [...(normalizedSelectedUsers as string[]), user.username];

      onChange(updatedSelection);
    }


  };

  const selectedUserObjects = safeUsers.filter((user) => isUserSelected(user));

  return (
    <div className="space-y-2 mb-4">
      <style>{`
        .scrollbar-custom {
          scrollbar-width: thin;
          scrollbar-color: rgba(139, 92, 246, 0.3) rgba(0, 0, 0, 0.2);
        }

        .scrollbar-custom::-webkit-scrollbar {
          width: 6px;
        }

        .scrollbar-custom::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
        }

        .scrollbar-custom::-webkit-scrollbar-thumb {
          background-color: rgba(139, 92, 246, 0.3);
          border-radius: 3px;
          transition: background-color 0.2s;
        }

        .scrollbar-custom::-webkit-scrollbar-thumb:hover {
          background-color: rgba(139, 92, 246, 0.5);
        }
      `}</style>

      <label className="block text-sm font-medium text-gray-300">
        Assign Users
      </label>

      <div className="relative" ref={dropdownRef}>
        {/* Sélecteur */}
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="w-full bg-gray-800/50 border border-gray-700 rounded-xl px-4 py-3 
            text-white cursor-pointer hover:bg-gray-800/70 transition-colors duration-200"
        >
          {selectedUserObjects.length === 0 ? (
            <span className="text-gray-400">Select users...</span>
          ) : (
            <div className="flex flex-wrap gap-2">
              {selectedUserObjects.map((user) => (
                <span
                  key={user.id}
                  className="inline-flex items-center px-2 py-1 rounded-lg bg-violet-500/20 text-violet-400 text-sm"
                >
                  <User size={14} className="mr-1" />
                  {user.username}
                </span>
              ))}
            </div>
          )}
        </div>

        {/* Dropdown */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="absolute z-50 w-full mt-2 bg-gray-900 border border-gray-700 
                rounded-xl shadow-xl overflow-hidden max-h-60 overflow-y-auto scrollbar-custom"
              onClick={(e) => e.stopPropagation()} 
            >
              <div className="bg-gray-900">
                {safeUsers.length > 0 ? (
                  safeUsers.map((user) => (
                    <div
                      key={user.id}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleUser(user);
                      }}
                      className={`flex items-center gap-3 p-3 cursor-pointer transition-colors duration-200
    ${isUserSelected(user) ? "bg-violet-500/20" : "hover:bg-gray-800"}`}
                    >
                      <div className="w-8 h-8 rounded-lg bg-violet-500/10 flex items-center justify-center">
                        {user.pfp ? (
                          <img
                            src={user.pfp}
                            alt={user.username}
                            className="w-full h-full object-cover rounded-lg"
                          />
                        ) : (
                          <User size={16} className="text-violet-400" />
                        )}
                      </div>
                      <div>
                        <div className="text-white font-medium">
                          {user.username}
                        </div>
                        <div className="text-gray-400 text-sm">{user.role}</div>
                      </div>
                      {isUserSelected(user) && (
                        <Check size={16} className="ml-auto text-violet-400" />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="p-4 text-gray-400 text-center">
                    No users available
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default UserSelector;
