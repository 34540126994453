import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LogIn, UserPlus, Mail, Lock, Eye, EyeOff, ArrowRight, Loader2, AlertCircle, MailIcon, Check, AlertOctagon, Clock, MessageSquare, Copy } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import gsap from 'gsap';
import { useAuth } from './AuthContext';
import { API_URL } from 'components/api_config';


interface LoginFormData {
  email: string;
  password: string;
}

interface Error {
    message: string;
    isVisible: boolean;
    type: 'error' | 'warning' | 'info';
    code?: string;
  }

enum LoginErrorCodes {
    MISSING_FIELDS = 'MISSING_FIELDS',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    ACCOUNT_NOT_CONFIRMED = 'ACCOUNT_NOT_CONFIRMED',
    SERVER_ERROR = 'SERVER_ERROR',
    SUCCESS = 'SUCCESS'
  }



export const LoginPage: React.FC = () => {
    const formRef = useRef<HTMLDivElement>(null);
    const logoRef = useRef<HTMLImageElement>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error>({ 
      message: '', 
      isVisible: false,
      type: 'error',
      code: ''
    });
    
    const { login, isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      const checkInitialAuth = async () => {
        try {
          const token = localStorage.getItem('token');
          if (token) {
            const response = await axios.get(`${API_URL}/auth/verify`, {
              headers: { Authorization: `Bearer ${token}` }
            });
            
            if (response.status === 200) {
              const username = localStorage.getItem('username');
              if (username) {
                login(token, username);
                navigate('/');
              }
            }
          }
        } catch (error) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
        }
      };
  
      checkInitialAuth();
    }, []);
  
    const [formData, setFormData] = useState<LoginFormData>({
      email: '',
      password: ''
    });
  
    useEffect(() => {
      const ctx = gsap.context(() => {
        gsap.from(logoRef.current, {
          duration: 1.2,
          y: -100,
          opacity: 0,
          ease: 'elastic.out(1, 0.5)',
          delay: 0.2
        });
  
        gsap.from('.auth-card', {
          duration: 1,
          y: 100,
          opacity: 0,
          ease: 'power4.out',
          delay: 0.5
        });
  
        gsap.from('.form-element', {
          duration: 0.8,
          y: 20,
          opacity: 0,
          stagger: 0.2,
          ease: 'power3.out',
          delay: 1
        });
      }, formRef);
  
      return () => ctx.revert();
    }, []);
  
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      setError({ message: '', isVisible: false, type: 'error', code: '' });
    
      try {
        const response = await axios.post<{
          token: string;
          user: { username: string }
        }>(`${API_URL}/auth/login`, {
          email: formData.email,
          password: formData.password
        });
    
        if (response.status === 200 && response.data.token) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('username', response.data.user.username);
          login(response.data.token, response.data.user.username);
          

          const from = (location.state as any)?.from?.pathname || '/';
          navigate(from, { replace: true });
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const { data, status } = err.response;
          
          switch (data.code) {
            case LoginErrorCodes.ACCOUNT_NOT_CONFIRMED:
              setError({
                message: 'Your account is awaiting approval. Our team will review it shortly.',
                isVisible: true,
                type: 'warning',
                code: data.code
              });
              break;
  
            case LoginErrorCodes.INVALID_CREDENTIALS:
              setError({
                message: 'Invalid email or password. Please try again.',
                isVisible: true,
                type: 'error',
                code: data.code
              });
              break;
  
            case LoginErrorCodes.MISSING_FIELDS:
              setError({
                message: 'Please fill in all required fields.',
                isVisible: true,
                type: 'error',
                code: data.code
              });
              break;
  
            case LoginErrorCodes.SERVER_ERROR:
              setError({
                message: 'An unexpected error occurred. Please try again later.',
                isVisible: true,
                type: 'error',
                code: data.code
              });
              break;
  
            default:
              setError({
                message: data.message || 'An unexpected error occurred',
                isVisible: true,
                type: 'error',
                code: 'UNKNOWN_ERROR'
              });
          }
  
          gsap.from('.error-message', {
            y: -20,
            opacity: 0,
            duration: 0.3,
            ease: 'power2.out'
          });
        }
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    };
  
    const isFormValid = (): boolean => {
      return formData.email.includes('@') && formData.password.length >= 6;
    };
  
    const getErrorIcon = () => {
      switch (error.type) {
        case 'warning':
          return <AlertOctagon className="text-yellow-500" size={20} />;
        case 'info':
          return <Clock className="text-blue-500" size={20} />;
        default:
          return <AlertCircle className="text-red-500" size={20} />;
      }
    };
  
    const getErrorStyles = () => {
      switch (error.type) {
        case 'warning':
          return 'bg-yellow-500/10 border-yellow-500/50 text-yellow-500';
        case 'info':
          return 'bg-blue-500/10 border-blue-500/50 text-blue-500';
        default:
          return 'bg-red-500/10 border-red-500/50 text-red-500';
      }
    };
  
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23] flex flex-col items-center justify-center p-4">
        <div ref={formRef} className="w-full max-w-md">
          <div className="flex flex-col items-center mb-8">
            <motion.img
              ref={logoRef}
              src="/assets/images/big-empire-drop-logo.png"
              alt="Empire Drop Logo"
              className="h-16 mb-6"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            />
          </div>
  
          <motion.div 
            className="auth-card bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-8 rounded-2xl shadow-2xl"
            initial={false}
          >
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-white mb-2">Welcome Back</h2>
              <p className="text-gray-400">Sign in to continue your journey</p>
            </div>
  
            {error.isVisible && (
              <motion.div 
                className={`error-message border rounded-lg p-4 mb-6 flex items-center space-x-2 ${getErrorStyles()}`}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                {getErrorIcon()}
                <p className="text-sm">{error.message}</p>
              </motion.div>
            )}
  
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="form-element">
                <div className="relative">
                  <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="w-full bg-[#0E1015] text-white rounded-lg pl-12 pr-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                </div>
              </div>
  
              <div className="form-element">
                <div className="relative">
                  <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="w-full bg-[#0E1015] text-white rounded-lg pl-12 pr-12 py-3 focus:outline-none focus:ring-2 focus:ring-[#611BE2] transition-all duration-300"
                    value={formData.password}
                    onChange={handleInputChange}
                    disabled={isLoading}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white transition-colors duration-300"
                    disabled={isLoading}
                  >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>
  
              <div className="form-element">
                <motion.button
                  type="submit"
                  className="w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white rounded-lg py-3 flex items-center justify-center space-x-2 hover:opacity-90 transition-opacity duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={isLoading || !isFormValid()}
                >
                  {isLoading ? (
                    <Loader2 className="animate-spin" size={24} />
                  ) : (
                    <>
                      <span>Sign In</span>
                      <ArrowRight size={20} />
                    </>
                  )}
                </motion.button>
              </div>
            </form>
  

          </motion.div>
        </div>
  
        <AnimatePresence>
          {isLoading && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              className="fixed bottom-4 right-4 bg-[#1A1C23] text-white p-4 rounded-lg shadow-lg flex items-center space-x-2"
            >
              <Loader2 className="animate-spin text-[#611BE2]" size={20} />
              <span>Signing in...</span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  };

  export const RegisterSuccess: React.FC<{ email: string }> = ({ email }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [copied, setCopied] = useState(false);
    
    useEffect(() => {
      const ctx = gsap.context(() => {
        // Animation de l'icône de succès
        gsap.from('.success-icon', {
          scale: 0,
          rotation: -180,
          opacity: 0,
          duration: 1,
          ease: 'elastic.out(1, 0.5)',
          delay: 0.3
        });
        
        // Animation du check à l'intérieur
        gsap.from('.check-icon', {
          scale: 0,
          opacity: 0,
          duration: 0.5,
          ease: 'back.out(1.7)',
          delay: 0.8
        });
  
        // Animation des textes avec effet cascade
        gsap.from('.success-text', {
          y: 30,
          opacity: 0,
          duration: 0.7,
          stagger: 0.15,
          ease: 'power3.out',
          delay: 1
        });
        
        // Animation des boutons
        gsap.from('.action-button', {
          y: 20,
          opacity: 0,
          duration: 0.6,
          stagger: 0.2,
          ease: 'power3.out',
          delay: 1.5
        });
  
        // Animation des confettis/particules
        const particles = gsap.utils.toArray('.particle');
        particles.forEach((particle: any) => {
          // Position initiale au centre
          gsap.set(particle, {
            x: 0,
            y: 0,
            scale: 0,
            opacity: 0,
            top: '50%',
            left: '50%',
          });
          
          // Animation explosive
          gsap.to(particle, {
            x: `random(-${window.innerWidth/2}, ${window.innerWidth/2})`,
            y: `random(-${window.innerHeight/2}, ${window.innerHeight/2})`,
            scale: 'random(0.5, 1.5)',
            opacity: 1,
            duration: 'random(1, 2)',
            ease: 'power3.out',
            delay: 'random(0.2, 0.6)'
          });
          
          // Animation de flottement continue
          gsap.to(particle, {
            y: '+=20',
            x: '+=10',
            rotation: '+=random(-40, 40)',
            duration: 'random(3, 6)',
            ease: 'sine.inOut',
            repeat: -1,
            yoyo: true,
            delay: 'random(0, 2)'
          });
        });
        
        // Animation des anneaux lumineux
        gsap.from('.glow-ring', {
          scale: 0.5,
          opacity: 0,
          duration: 1.2,
          ease: 'power2.out',
          delay: 0.2
        });
        
        gsap.to('.glow-ring', {
          scale: 1.1,
          opacity: 0.6,
          duration: 2,
          repeat: -1,
          yoyo: true,
          ease: 'sine.inOut'
        });
        
      }, containerRef);
  
      return () => ctx.revert();
    }, []);
    
    const copyEmailToClipboard = () => {
      navigator.clipboard.writeText(email).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    };
  
    return (
      <div ref={containerRef} className="relative min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23] flex flex-col items-center justify-center p-4 overflow-hidden">
        {/* Effets lumineux */}
        <div className="glow-ring absolute w-[500px] h-[500px] rounded-full bg-gradient-to-r from-[#611BE2]/10 to-[#8B5CF6]/10 blur-2xl"></div>
        <div className="glow-ring absolute w-[700px] h-[700px] rounded-full bg-gradient-to-r from-[#611BE2]/5 to-[#8B5CF6]/5 blur-3xl" style={{ animationDelay: '0.5s' }}></div>
        
        {/* Particules/confettis */}
        {[...Array(30)].map((_, i) => (
          <div
            key={i}
            className={`particle absolute ${i % 3 === 0 
              ? 'bg-[#611BE2] w-2 h-2' 
              : i % 3 === 1 
                ? 'bg-[#8B5CF6] w-3 h-3' 
                : 'bg-gradient-to-br from-[#611BE2] to-[#8B5CF6] w-2.5 h-2.5'}`}
            style={{
              borderRadius: i % 4 === 0 ? '50%' : i % 4 === 1 ? '2px' : i % 4 === 2 ? '4px' : '50% 0 50% 50%',
              opacity: Math.random() * 0.7 + 0.3,
              boxShadow: i % 5 === 0 ? '0 0 8px rgba(97, 27, 226, 0.8)' : 'none'
            }}
          />
        ))}
  
        <div className="max-w-md w-full space-y-8 text-center relative z-10">
          <div className="success-icon relative inline-block">
            <div className="absolute inset-0 bg-gradient-to-br from-[#611BE2]/20 to-[#8B5CF6]/20 rounded-full blur-xl"></div>
            <div className="relative bg-[#1A1C23] p-8 rounded-full mb-8 shadow-xl border border-gray-800">
              <div className="bg-gradient-to-br from-[#611BE2]/30 to-[#8B5CF6]/30 p-6 rounded-full flex items-center justify-center">
                <Check className="check-icon w-16 h-16 text-[#611BE2]" />
              </div>
            </div>
          </div>
  
          <h2 className="success-text text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white via-indigo-200 to-white mb-4">
            Registration Successful!
          </h2>
          
          <p className="success-text text-gray-300 text-lg mb-3">
            Thank you for registering with Empire Drop. Our team will review your account shortly.
          </p>
          
          
          <div className="success-text flex flex-col sm:flex-row gap-4 mt-8 justify-center">
            <motion.a
              href="/login"
              className="action-button bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white rounded-lg py-3.5 px-6 flex items-center justify-center space-x-2 shadow-lg shadow-[#611BE2]/20 hover:shadow-[#611BE2]/40 hover:shadow-xl transition-all duration-300"
              whileHover={{ scale: 1.03, y: -2 }}
              whileTap={{ scale: 0.97 }}
            >
              <LogIn size={18} />
              <span className="font-medium">Sign In Now</span>
            </motion.a>
            
          
          </div>
          
  
        </div>
        
    
      </div>
    );
  };


interface RegisterComponents {
  RegisterPage: React.FC;
}


export default {
  LoginPage,
};