import { motion, AnimatePresence, useAnimation } from "framer-motion";
import {
  Share2,
  Copy,
  RefreshCw,
  Clipboard,
  Check,
  Users,
  UserPlus,
  Settings2,
  X,
  ChevronRight,
  Mail,
  Link as LinkIcon,
  Sparkles,
  Filter,
  Tag,
  Clock,
  ArrowDownToLine,
  FileText,
  BarChart4,
  AlertTriangle,
  Trash2,
  Search,
} from "lucide-react";
import axios from "axios";
import { API_URL } from "components/api_config";
import { toast } from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import ConfirmModal from "../Modals/Confirmation";

interface InvitationCode {
  code: string;
  createdAt: string;
  expiresAt: string;
  usedBy: string | null;
  redeemedBy: string | null;
  used: boolean;
  createdBy: string;
}

const InvitePage: React.FC = () => {
  const [currentCode, setCurrentCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [inviteCodes, setInviteCodes] = useState<InvitationCode[]>([]);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [codeToDelete, setCodeToDelete] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [creators, setCreators] = useState<string[]>([]);
  const [selectedCreator, setSelectedCreator] = useState<string>("all");
  const [activeTab, setActiveTab] = useState<"generate" | "history">(
    "generate"
  );
  const [filterStatus, setFilterStatus] = useState<"all" | "used" | "unused">(
    "all"
  );
  const inviteLinkRef = useRef<HTMLInputElement>(null);
  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const controls = useAnimation();
  const sparkleControls = useAnimation();

  const sequence = async () => {
    await controls.start({
      scale: [1, 1.03, 1],
      transition: { duration: 0.4 },
    });
    await sparkleControls.start({
      opacity: [0, 1, 0],
      scale: [0.9, 1.1, 0.9],
      transition: { duration: 1.5 },
    });
  };

  useEffect(() => {
    fetchInviteCodes();
  }, []);

  const getToken = () => {
    return localStorage.getItem("token") || "";
  };

  const deleteInviteCode = async (code: string) => {
    try {
      setLoading(true);
      const token = getToken();
      await axios.delete(`${API_URL}/invite/${code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success("Invitation code deleted successfully");
      fetchInviteCodes();
      setShowDeleteConfirm(false);
    } catch (error) {
      console.error("Error deleting invitation code:", error);
      toast.error("Failed to delete invitation code");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inviteCodes.length > 0) {
      const uniqueCreators = Array.from(
        new Set(inviteCodes.map((code) => code.createdBy))
      );
      setCreators(uniqueCreators);
    }
  }, [inviteCodes]);

  const fetchInviteCodes = async () => {
    try {
      setLoading(true);
      const token = getToken();
      const response = await axios.get(`${API_URL}/invite/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const codes = response.data.inviteCodes || [];
      setInviteCodes(codes);
    } catch (error) {
      console.error("Error fetching invitation codes:", error);
      toast.error("Failed to fetch invitation codes");
    } finally {
      setLoading(false);
    }
  };

  const generateInviteCode = async () => {
    try {
      setIsGenerating(true);
      const response = await axios.post(`${API_URL}/invite/create`);

      setCurrentCode(response.data.inviteCode.code);

      sequence();
      fetchInviteCodes();

      toast.success("New invitation code generated!");
    } catch (error) {
      console.error("Error generating invitation code:", error);
      toast.error("Failed to generate invitation code");
    } finally {
      setIsGenerating(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setShowTooltip(true);

      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }

      tooltipTimeoutRef.current = setTimeout(() => {
        setCopied(false);
        setShowTooltip(false);
      }, 2000);

      toast.success("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy:", err);
      toast.error("Failed to copy link");
    }
  };

  const getStatusColor = (status: boolean) => {
    return status
      ? "bg-green-400/10 text-green-400 border-green-400/20"
      : "bg-amber-400/10 text-amber-400 border-amber-400/20";
  };

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const filteredCodes = inviteCodes.filter((code) => {
    if (filterStatus === "used" && !code.used) return false;
    if (filterStatus === "unused" && code.used) return false;
    if (selectedCreator !== "all" && code.createdBy !== selectedCreator)
      return false;

    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      return (
        code.code.toLowerCase().includes(query) ||
        (code.createdBy && code.createdBy.toLowerCase().includes(query)) ||
        (code.redeemedBy && code.redeemedBy.toLowerCase().includes(query))
      );
    }

    return true;
  });

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0E1015] to-[#1A1C23] p-6 md:p-8">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-white mb-2 flex items-center">
            <UserPlus className="mr-3 text-indigo-400" />
            Invitation System
          </h1>
          <p className="text-gray-400 max-w-3xl">
            Generate unique invitation codes for new users. Only those with
            valid invitation links can register on the platform.
          </p>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
          {/* Left Side - Stats & Code Generator */}
          <div className="xl:col-span-2 space-y-6">
            {/* Stats Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <motion.div
                whileHover={{
                  y: -3,
                  boxShadow: "0 10px 25px -5px rgba(79, 70, 229, 0.1)",
                }}
                className="flex items-center gap-3 p-4 bg-gradient-to-br from-indigo-500/10 to-indigo-500/5 rounded-xl border border-indigo-500/20 shadow-lg shadow-indigo-500/5"
              >
                <div className="p-2 bg-indigo-500/10 rounded-lg">
                  <Users className="h-5 w-5 text-indigo-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Total Invites</p>
                  <p className="text-xl font-semibold text-white">
                    {inviteCodes.length}
                  </p>
                </div>
              </motion.div>

              <motion.div
                whileHover={{
                  y: -3,
                  boxShadow: "0 10px 25px -5px rgba(34, 197, 94, 0.1)",
                }}
                className="flex items-center gap-3 p-4 bg-gradient-to-br from-green-500/10 to-green-500/5 rounded-xl border border-green-500/20 shadow-lg shadow-green-500/5"
              >
                <div className="p-2 bg-green-500/10 rounded-lg">
                  <Check className="h-5 w-5 text-green-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Used</p>
                  <p className="text-xl font-semibold text-white">
                    {inviteCodes.filter((code) => code.used).length}
                  </p>
                </div>
              </motion.div>

              <motion.div
                whileHover={{
                  y: -3,
                  boxShadow: "0 10px 25px -5px rgba(251, 191, 36, 0.1)",
                }}
                className="flex items-center gap-3 p-4 bg-gradient-to-br from-amber-500/10 to-amber-500/5 rounded-xl border border-amber-500/20 shadow-lg shadow-amber-500/5"
              >
                <div className="p-2 bg-amber-500/10 rounded-lg">
                  <Tag className="h-5 w-5 text-amber-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-400">Available</p>
                  <p className="text-xl font-semibold text-white">
                    {inviteCodes.filter((code) => !code.used).length}
                  </p>
                </div>
              </motion.div>
            </div>

            {/* Tab Navigation */}
            <div className="border-b border-gray-800 flex">
              <button
                className={`px-4 py-2 font-medium flex items-center gap-2 ${
                  activeTab === "generate"
                    ? "text-indigo-400 border-b-2 border-indigo-400"
                    : "text-gray-400 hover:text-gray-300"
                }`}
                onClick={() => setActiveTab("generate")}
              >
                <Sparkles size={18} /> Generate Code
              </button>
              <button
                className={`px-4 py-2 font-medium flex items-center gap-2 ${
                  activeTab === "history"
                    ? "text-indigo-400 border-b-2 border-indigo-400"
                    : "text-gray-400 hover:text-gray-300"
                }`}
                onClick={() => setActiveTab("history")}
              >
                <Clock size={18} /> Invitation History
              </button>
            </div>

            {/* Generate Code Tab */}
            {activeTab === "generate" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-gray-800/40 backdrop-blur-sm rounded-xl border border-gray-700/50 shadow-xl overflow-hidden"
              >
                <div className="p-6">
                  <div className="flex items-center justify-between mb-6">
                    <h2 className="text-xl font-semibold text-white flex items-center gap-2">
                      <UserPlus className="text-indigo-400" /> Invite New User
                    </h2>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      disabled={isGenerating}
                      onClick={generateInviteCode}
                      className={`px-4 py-2 rounded-lg transition-all flex items-center gap-2 shadow-lg shadow-indigo-500/10 
                        ${
                          isGenerating
                            ? "bg-indigo-600/50 cursor-not-allowed"
                            : "bg-indigo-600 hover:bg-indigo-500"
                        }`}
                    >
                      {isGenerating ? (
                        <>
                          <RefreshCw size={18} className="animate-spin" />{" "}
                          Generating...
                        </>
                      ) : (
                        <>
                          <RefreshCw size={18} /> Generate Code
                        </>
                      )}
                    </motion.button>
                  </div>

                  <div className="space-y-6">
                    <motion.div
                      animate={controls}
                      className="bg-gray-900/80 rounded-xl p-6 border border-indigo-500/20 shadow-lg shadow-indigo-500/5 relative overflow-hidden"
                    >
                      <motion.div
                        animate={sparkleControls}
                        initial={{ opacity: 0 }}
                        className="absolute inset-0 bg-gradient-to-r from-indigo-500/10 to-purple-500/10"
                      />
                      <div className="relative z-10 space-y-4">
                        <div className="flex items-center justify-between">
                          <h3 className="text-white font-medium">
                            Invitation Code
                          </h3>
                          <div className="flex items-center gap-2">
                            <span className="text-xs bg-indigo-500/20 text-indigo-300 px-2 py-1 rounded-full">
                              Valid for 7 days
                            </span>
                          </div>
                        </div>

                        <div className="bg-black/30 p-3 rounded-lg border border-gray-800 flex items-center justify-between break-all">
                          <code className="text-indigo-300 font-mono select-all">
                            {currentCode ||
                              "Generate a code to start inviting users"}
                          </code>
                          {currentCode && (
                            <motion.button
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() => copyToClipboard(currentCode)}
                              className="ml-2 p-1.5 rounded-lg bg-indigo-500/20 text-indigo-400 hover:bg-indigo-500/30"
                              title="Copy code"
                            >
                              {copied ? (
                                <Check size={18} />
                              ) : (
                                <Copy size={18} />
                              )}
                            </motion.button>
                          )}
                        </div>

                        <div className="space-y-3">
                          <p className="text-gray-400 text-sm">
                            Complete invitation link:
                          </p>
                          <div className="relative">
                            <input
                              ref={inviteLinkRef}
                              type="text"
                              readOnly
                              value={
                                currentCode
                                  ? `https://ed.selaris.app/register?invite=${currentCode}`
                                  : "Generate a code first"
                              }
                              className="w-full bg-black/30 border border-gray-800 rounded-lg px-3 py-2 pr-10 text-white font-mono text-sm"
                            />
                            <motion.button
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              onClick={() =>
                                currentCode &&
                                copyToClipboard(
                                  `https://ed.selaris.app/register?invite=${currentCode}`
                                )
                              }
                              className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1.5 rounded-lg bg-indigo-500/20 text-indigo-400 hover:bg-indigo-500/30"
                              disabled={!currentCode}
                              title="Copy invitation link"
                            >
                              {copied ? (
                                <Check size={18} />
                              ) : (
                                <LinkIcon size={18} />
                              )}
                            </motion.button>
                            {showTooltip && (
                              <div className="absolute mt-2 right-0 px-2 py-1 bg-green-500 text-white text-xs rounded">
                                Copied!
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </motion.div>

                    <div className="p-4 bg-blue-500/10 rounded-xl border border-blue-500/20">
                      <div className="flex gap-3">
                        <div className="p-2 rounded-lg bg-blue-500/20">
                          <Mail className="h-5 w-5 text-blue-400" />
                        </div>
                        <div>
                          <h3 className="text-white font-medium mb-1">
                            Share Invitation
                          </h3>
                          <p className="text-gray-400 text-sm mb-3">
                            Send this link to the person you want to invite. The
                            code is valid for 7 days and can only be used once.
                          </p>
                          <div className="flex flex-wrap gap-2">
                            <motion.button
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              onClick={() =>
                                currentCode &&
                                copyToClipboard(
                                  `https://ed.selaris.app/register?invite=${currentCode}`
                                )
                              }
                              className="px-3 py-1.5 bg-white/10 hover:bg-white/20 rounded-lg text-white text-sm flex items-center gap-2"
                              disabled={!currentCode}
                            >
                              <Copy size={16} /> Copy Link
                            </motion.button>
                            <motion.a
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              href={`mailto:?subject=Your%20Invitation%20to%20EmpireDrop&body=You've%20been%20invited%20to%20join%20EmpireDrop!%0A%0AUse%20this%20link%20to%20register:%20https://ed.selaris.app/register?invite=${currentCode}`}
                              className={`px-3 py-1.5 bg-blue-500/20 hover:bg-blue-500/30 rounded-lg text-blue-300 text-sm flex items-center gap-2 ${
                                !currentCode
                                  ? "opacity-50 pointer-events-none"
                                  : ""
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Mail size={16} /> Send Email
                            </motion.a>
                            <motion.button
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              onClick={() => {
                                if (currentCode) {
                                  navigator
                                    .share({
                                      title: "Your Invitation to EmpireDrop",
                                      text: "You've been invited to join EmpireDrop!",
                                      url: `https://ed.selaris.app/register?invite=${currentCode}`,
                                    })
                                    .catch((err) =>
                                      console.log("Error sharing:", err)
                                    );
                                }
                              }}
                              className={`px-3 py-1.5 bg-purple-500/20 hover:bg-purple-500/30 rounded-lg text-purple-300 text-sm flex items-center gap-2 ${
                                !currentCode || !navigator.share
                                  ? "opacity-50 pointer-events-none"
                                  : ""
                              }`}
                            >
                              <Share2 size={16} /> Share
                            </motion.button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}

            {/* History Tab */}
            {activeTab === "history" && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="space-y-4"
              >
                {/* Filters */}
                <div className="bg-gray-800/40 backdrop-blur-sm rounded-xl p-4 border border-gray-700/50 shadow-xl">
                  <div className="flex flex-wrap gap-4 items-end">
                    {/* Filtre par statut */}
                    <div className="flex-grow min-w-[200px]">
                      <label className="block text-xs text-gray-400 mb-1">
                        Status
                      </label>
                      <div className="flex bg-gray-800 rounded-lg overflow-hidden">
                        <button
                          onClick={() => setFilterStatus("all")}
                          className={`px-3 py-1.5 text-sm flex-1 ${
                            filterStatus === "all"
                              ? "bg-indigo-500 text-white"
                              : "text-gray-400 hover:bg-gray-700"
                          }`}
                        >
                          All
                        </button>
                        <button
                          onClick={() => setFilterStatus("used")}
                          className={`px-3 py-1.5 text-sm flex-1 ${
                            filterStatus === "used"
                              ? "bg-green-500 text-white"
                              : "text-gray-400 hover:bg-gray-700"
                          }`}
                        >
                          Used
                        </button>
                        <button
                          onClick={() => setFilterStatus("unused")}
                          className={`px-3 py-1.5 text-sm flex-1 ${
                            filterStatus === "unused"
                              ? "bg-amber-500 text-white"
                              : "text-gray-400 hover:bg-gray-700"
                          }`}
                        >
                          Available
                        </button>
                      </div>
                    </div>

                    {/* Filtre par créateur */}
                    {creators.length > 0 && (
                      <div className="flex-grow min-w-[200px]">
                        <label className="block text-xs text-gray-400 mb-1">
                          Created By
                        </label>
                        <select
                          value={selectedCreator}
                          onChange={(e) => setSelectedCreator(e.target.value)}
                          className="w-full bg-gray-800 rounded-lg px-3 py-1.5 text-gray-300 border border-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        >
                          <option value="all">All Creators</option>
                          {creators.map((creator) => (
                            <option key={creator} value={creator}>
                              {creator}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {/* Recherche */}
                    <div className="flex-grow min-w-[300px]">
                      <label className="block text-xs text-gray-400 mb-1">
                        Search
                      </label>
                      <div className="relative">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                        <input
                          type="text"
                          placeholder="Search by code or user..."
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="w-full bg-gray-800 rounded-lg pl-10 pr-4 py-1.5 text-gray-300 border border-gray-700 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                        />
                        {searchQuery && (
                          <button
                            onClick={() => setSearchQuery("")}
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-300"
                          >
                            <X size={14} />
                          </button>
                        )}
                      </div>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={fetchInviteCodes}
                      className="p-2 bg-gray-800 rounded-lg text-gray-400 hover:text-gray-300 hover:bg-gray-700"
                      title="Refresh"
                    >
                      <RefreshCw size={20} />
                    </motion.button>
                  </div>

                  <div className="mt-3 text-sm text-gray-400">
                    Showing{" "}
                    <span className="font-medium text-white">
                      {filteredCodes.length}
                    </span>{" "}
                    of{" "}
                    <span className="font-medium text-white">
                      {inviteCodes.length}
                    </span>{" "}
                    invitation codes
                  </div>
                </div>

                {/* Invitation Code List */}
                <div className="bg-gray-800/40 backdrop-blur-sm rounded-xl border border-gray-700/50 shadow-xl overflow-hidden">
                  {loading ? (
                    <div className="p-8 flex justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-500"></div>
                    </div>
                  ) : filteredCodes.length === 0 ? (
                    <div className="p-8 text-center">
                      <div className="w-16 h-16 bg-gray-800/80 rounded-full flex items-center justify-center mx-auto mb-3">
                        <Tag className="h-8 w-8 text-gray-500" />
                      </div>
                      <h3 className="text-gray-400 font-medium mb-1">
                        No invitation codes found
                      </h3>
                      <p className="text-gray-500 text-sm">
                        {searchQuery ||
                        filterStatus !== "all" ||
                        selectedCreator !== "all"
                          ? "Try adjusting your filters"
                          : "Generate new codes to see them here"}
                      </p>
                    </div>
                  ) : (
                    <div className="overflow-x-auto">
                      <table className="w-full">
                        <thead>
                          <tr className="bg-black/20">
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase">
                              Code
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase">
                              Created
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase">
                              Created By
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase">
                              Status
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-400 uppercase">
                              Used By
                            </th>
                            <th className="px-4 py-3 text-right text-xs font-medium text-gray-400 uppercase">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-800/50">
                          {filteredCodes.map((code, index) => (
                            <motion.tr
                              key={index}
                              initial={{ opacity: 0, y: 10 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ delay: index * 0.05 }}
                              className="bg-gray-800/20 hover:bg-gray-800/40 transition-colors"
                            >
                              <td className="px-4 py-3 whitespace-nowrap">
                                <div className="flex items-center">
                                  <code className="text-indigo-300 font-mono text-sm">
                                    {code.code}
                                  </code>
                                  <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() => copyToClipboard(code.code)}
                                    className="ml-2 p-1 rounded hover:bg-gray-700"
                                  >
                                    <Copy
                                      size={14}
                                      className="text-gray-400 hover:text-gray-300"
                                    />
                                  </motion.button>
                                </div>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-gray-300 text-sm">
                                {formatDate(code.createdAt)}
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-gray-300 text-sm">
                                {code.createdBy}
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap">
                                <span
                                  className={`px-2 py-1 rounded-lg text-xs font-medium border ${getStatusColor(
                                    code.used
                                  )}`}
                                >
                                  {code.used ? "Used" : "Available"}
                                </span>
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-sm">
                                {code.redeemedBy ? (
                                  <div className="flex items-center gap-2">
                                    <div className="w-6 h-6 rounded-full bg-gray-700 flex items-center justify-center text-xs text-white">
                                      {code.redeemedBy.charAt(0).toUpperCase()}
                                    </div>
                                    <span className="text-gray-300">
                                      {code.redeemedBy}
                                    </span>
                                  </div>
                                ) : (
                                  <span className="text-gray-500">-</span>
                                )}
                              </td>
                              <td className="px-4 py-3 whitespace-nowrap text-right">
                                <div className="flex justify-end gap-2">
                                  <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.9 }}
                                    onClick={() =>
                                      copyToClipboard(
                                        `https://ed.selaris.app/register?invite=${code.code}`
                                      )
                                    }
                                    className="p-1.5 rounded-lg bg-indigo-500/10 text-indigo-400 hover:bg-indigo-500/20"
                                    title="Copy invitation link"
                                  >
                                    <LinkIcon size={16} />
                                  </motion.button>

                                  {!code.used && (
                                    <motion.button
                                      whileHover={{ scale: 1.1 }}
                                      whileTap={{ scale: 0.9 }}
                                      onClick={() => {
                                        setCodeToDelete(code.code);
                                        setShowDeleteConfirm(true);
                                      }}
                                      className="p-1.5 rounded-lg bg-red-500/10 text-red-400 hover:bg-red-500/20"
                                      title="Delete invitation code"
                                    >
                                      <Trash2 size={16} />
                                    </motion.button>
                                  )}
                                </div>
                              </td>
                            </motion.tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </motion.div>
            )}
            {showDeleteConfirm && (
              <ConfirmModal
                title="Delete Invitation Code"
                description="Are you sure you want to delete this invitation code? This action cannot be undone."
                onConfirm={() => {
                  if (codeToDelete) deleteInviteCode(codeToDelete);
                }}
                onCancel={() => setShowDeleteConfirm(false)}
              />
            )}
          </div>

          {/* Right Side - Tutorial & Help */}
          <div className="space-y-6">
            {/* How to Use */}
            <div className="bg-gray-800/40 backdrop-blur-sm rounded-xl border border-gray-700/50 shadow-xl p-6">
              <div className="mb-4 flex items-center gap-2">
                <div className="p-2 bg-indigo-500/10 rounded-lg">
                  <FileText className="h-5 w-5 text-indigo-400" />
                </div>
                <h2 className="text-xl font-semibold text-white">
                  How It Works
                </h2>
              </div>

              <div className="space-y-4">
                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm font-medium text-indigo-400">
                      1
                    </div>
                    <h3 className="text-white font-medium">
                      Generate Invitation Code
                    </h3>
                  </div>
                  <p className="text-gray-400 text-sm pl-8">
                    Click the "Generate Code" button to create a new unique
                    invitation code.
                  </p>
                </div>

                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm font-medium text-indigo-400">
                      2
                    </div>
                    <h3 className="text-white font-medium">Share the Link</h3>
                  </div>
                  <p className="text-gray-400 text-sm pl-8">
                    Copy the invitation link and share it with the person you
                    want to invite.
                  </p>
                </div>

                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm font-medium text-indigo-400">
                      3
                    </div>
                    <h3 className="text-white font-medium">
                      User Registration
                    </h3>
                  </div>
                  <p className="text-gray-400 text-sm pl-8">
                    The invited person follows the link and completes the
                    registration process.
                  </p>
                </div>

                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    <div className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm font-medium text-indigo-400">
                      4
                    </div>
                    <h3 className="text-white font-medium">
                      Track Invitation Status
                    </h3>
                  </div>
                  <p className="text-gray-400 text-sm pl-8">
                    Monitor which invitations have been used in the "Invitation
                    History" tab.
                  </p>
                </div>
              </div>

              <div className="mt-6 p-4 bg-amber-500/10 rounded-lg border border-amber-500/20">
                <div className="flex gap-3">
                  <div className="p-1.5 rounded-lg bg-amber-500/20">
                    <AlertTriangle className="h-5 w-5 text-amber-400" />
                  </div>
                  <div>
                    <h4 className="text-amber-300 font-medium text-sm mb-1">
                      Important Notes
                    </h4>
                    <ul className="text-gray-400 text-sm space-y-1">
                      <li>• Each invitation code can only be used once</li>
                      <li>• Codes expire after 7 days</li>
                      <li>• Users must use the exact link provided</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitePage;
