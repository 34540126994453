import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Gift,
  Eye,
  EyeOff,
  Plus,
  Tag,
  Box,
  Wallet,
  History,
  Send,
  Clock,
  Monitor,
  Power,
  ShieldCheck,
  EuroIcon,
  User,
  Search,
  Save,
  Star,
  Copy,
  Trash2,
  Edit,
  CheckCircle2,
  AlertCircle,
} from "lucide-react";
import gsap from "gsap";
import toast from "react-hot-toast";
import SavedPromoCodesCard from "./SavedPromoCodes/SavedPromoCodesCard";
import { API_URL } from "./api_config";
import BoxSelector from "./Helpers/BoxSelector";
import ActiveCodeDisplay from "./Helpers/PromoSection/ActiveCodeDisplay";
import Card, { CardContent, CardHeader, CardTitle } from "./ui/card";

export type PromoCodeType = "Deposit Bonus" | "Free Box" | "Balance";

interface Box {
  name: string;
  slug: string;
  image: string;
  price: number;
  categories: { name: string; slug: string; type: string }[];
}

interface Toast {
  id: number;
  message: string;
  type: "success" | "error";
}

interface PromoCode {
  id: number;
  code: string;
  value: number;
  usage: number;
  type: PromoCodeType;
  status: "active" | "used" | "expired";
  requirements: string;
  boxSlug?: string;
  usedBy?: string;
  usedAt?: Date;
  createdAt: Date;
}

interface HistoryEntry extends PromoCode {}

interface PaginationData {
  total: number;
  page: number;
  totalPages: number;
  limit: number;
}

interface SavedPromoCode {
  id: number;
  name: string;
  code: string;
  value: number;
  type: "Deposit Bonus" | "Free Box" | "Balance";
  requirements: string;
  usage: number;
  boxSlug: string | null;
  createdAt: Date;
}

const PromoCodeManager: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [currentPromoCode, setCurrentPromoCode] = useState<PromoCode | null>(
    null
  );
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState<PaginationData>({
    total: 0,
    page: 1,
    totalPages: 1,
    limit: 10,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedBox, setSelectedBox] = useState<Box | null>(null);
  const [boxes, setBoxes] = useState<Box[]>([]); // Add this state
  const [newCode, setNewCode] = useState({
    code: "",
    amount: 0,
    category: "Deposit Bonus" as PromoCodeType,
    requirements: "",
    usage: 0,
    boxSlug: "",
  });

  const cardRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    gsap.from(cardRef.current, {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "elastic.out(1, 0.8)",
    });

    gsap.from(titleRef.current, {
      duration: 1.5,
      scale: 0.5,
      opacity: 0,
      rotation: -10,
      ease: "bounce.out",
      delay: 0.2,
    });
  }, []);

  useEffect(() => {
    const fetchBoxes = async () => {
      try {
        const response = await fetch(
          "https://api.empiredrop.com/api/v1/boxes/search",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              brands: [],
              categories: [],
              order: "CHEAPEST",
              price_max: 500000,
              price_min: 0,
              tags: [],
              page_size: 100000
            }),
          }
        );

        const preData = await response.json();
        const data = await preData.data;
        const formattedBoxes = data.map((box: any) => ({
          name: box.name,
          slug: box.slug,
          image: box.image.startsWith("/")
            ? `https://cdn.empiredrop.com${box.image}`
            : `https://cdn.empiredrop.com/${box.image}`,
          price: box.price,
          categories: box.categories || [],
        }));
        setBoxes(formattedBoxes);
      } catch (error) {
        console.error("Error fetching boxes:", error);
      }
    };

    fetchBoxes();
  }, []);

  const fetchOverlayState = async () => {
    try {
      const response = await fetch(
        "https://empire-back.selaris.app/api/overlay/overlay-state"
      );
      if (!response.ok) throw new Error("Failed to fetch overlay state");
      const data = await response.json();
      if (data.status === "success") {
        setShowOverlay(data.data.isActive);
      }
    } catch (err) {
      console.error("Error fetching overlay state:", err);
    }
  };

  const addToast = (message: string, type: "success" | "error") => {
    const newToast: Toast = {
      id: Date.now(),
      message,
      type,
    };
    setToasts((current) => [...current, newToast]);

    setTimeout(() => {
      setToasts((current) =>
        current.filter((toast) => toast.id !== newToast.id)
      );
    }, 3000);
  };

  const fetchCurrentCode = async () => {
    try {
      const response = await fetch(
        "https://empire-back.selaris.app/api/current-code"
      );
      if (!response.ok) {
        if (response.status !== 404) {
          throw new Error("Failed to fetch current code");
        }
        setCurrentPromoCode(null);
        return;
      }

      const data = await response.json();
      if (data.status === "success") {
        setCurrentPromoCode(data.data.promoCode);
      }
    } catch (err) {
      console.error("Error fetching current code:", err);
    }
  };

  const fetchHistory = async (page = 1) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_URL}/promo-codes?page=${page}&limit=10`
      );
      if (!response.ok) throw new Error("Failed to fetch history");

      const data = await response.json();

      if (data.status === "success") {
        // Remplacer directement avec les nouvelles données au lieu de concaténer
        setHistory(data.data.promoCodeHistory);
        setPagination(data.data.pagination);
        setCurrentPage(page);
        setError(null);
      }
    } catch (err) {
      setError("Failed to load history");
      console.error("Error fetching history:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentCode();
    fetchHistory(currentPage);
    fetchOverlayState();

    const interval = setInterval(() => {
      fetchCurrentCode();
      fetchHistory(currentPage); // Utiliser la page courante au lieu de toujours recharger la page 1
      fetchOverlayState();
    }, 10000);

    return () => clearInterval(interval);
  }, [currentPage]);

  const handlePreviousPage = () => {
    const newPage = Math.max(1, pagination.page - 1);
    fetchHistory(newPage);
  };

  const handleNextPage = () => {
    const newPage = Math.min(pagination.totalPages, pagination.page + 1);
    fetchHistory(newPage);
  };

  const handleToggleOverlay = async () => {
    try {
      const response = await fetch(
        "https://empire-back.selaris.app/api/overlay/overlay-state",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isActive: !showOverlay,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to toggle overlay");

      const data = await response.json();
      if (data.status === "success") {
        setShowOverlay(data.data.isActive);
      }
    } catch (err) {
      console.error("Error toggling overlay:", err);
    }
  };

  const handleCategoryChange = (category: PromoCodeType) => {
    setNewCode({
      ...newCode,
      category,
      amount: 0,
      boxSlug: "",
    });
    setSelectedBox(null);
  };

  const handleUseTemplate = (savedCode: SavedPromoCode) => {
    setNewCode({
      code: savedCode.code,
      amount: savedCode.value,
      category: savedCode.type,
      requirements: savedCode.requirements || "",
      usage: savedCode.usage,
      boxSlug: savedCode.boxSlug || "",
    });

    if (savedCode.type === "Free Box" && savedCode.boxSlug) {
      const box = boxes.find((b) => b.slug === savedCode.boxSlug);
      if (box) {
        setSelectedBox(box);
      }
    }

    toast.success("Template loaded successfully!");
  };

  const handleBoxSelect = (box: Box) => {
    setSelectedBox(box);
    setNewCode({
      ...newCode,
      boxSlug: box.slug,
    });
  };

  const handleAddCode = async () => {
    if (
      !newCode.code ||
      (newCode.category !== "Free Box" && newCode.amount <= 0) ||
      (newCode.category === "Free Box" && !selectedBox)
    )
      return;

    try {
      const value =
        newCode.category === "Free Box"
          ? selectedBox!.price / 100
          : newCode.amount;

      const promoData = {
        code: newCode.code,
        value,
        type: newCode.category,
        requirements: newCode.requirements,
        boxSlug:
          newCode.category === "Free Box" ? selectedBox!.slug : undefined,
        usage: newCode.usage,
      };

      const createResponse = await fetch(API_URL + "/promo-add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(promoData),
      });

      if (!createResponse.ok) {
        throw new Error("Failed to create promo code");
      }

      const handleDelete = async (code: string) => {
        try {
          await fetch(`${API_URL}/saved-promo-codes-name/${code}`, {
            method: "DELETE",
          });
        } catch (error) {
          console.error("Error deleting code:", error);
        }
      };

      const setCurrentResponse = await fetch(API_URL + "/current-code", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(promoData),
      });

      if (!setCurrentResponse.ok) {
        throw new Error("Failed to set promo code as current");
      }

      const data = await setCurrentResponse.json();

      if (data.status === "success") {
        setCurrentPromoCode(data.data.promoCode);
        handleDelete(data.data.promoCode.code);
        setNewCode({
          code: "",
          amount: 0,
          category: "Deposit Bonus",
          requirements: "",
          usage: 0,
          boxSlug: "",
        });
        setSelectedBox(null);

        gsap.to(".success-flash", {
          duration: 0.3,
          backgroundColor: "rgba(97, 27, 226, 0.2)",
          yoyo: true,
          repeat: 1,
        });

        await Promise.all([fetchCurrentCode(), fetchHistory(1)]);
      }
    } catch (err) {
      console.error("Error setting promo code:", err);
    }
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6 text-gray-100">
      <div className="space-y-8 max-w-7xl mx-auto p-8 ">
        <div className="flex items-center justify-between mb-12">
          <div className="flex items-center space-x-12">
            <h1
              ref={titleRef}
              className="text-4xl font-bold text-white flex items-center"
            >
              <Gift className="mr-4 text-[#611BE2]" size={42} />
              Promo Code Manager
            </h1>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <SavedPromoCodesCard onUseTemplate={handleUseTemplate} />

          <Card className="success-flash">
            <CardHeader className="bg-gradient-to-r from-[#611BE2]/10 to-transparent">
              <CardTitle>
                <div className="flex items-center">
                  <Plus className="mr-3 text-[#611BE2]" size={24} />
                  Set New Promo Code
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-6">
                <div className="relative group">
                  <Tag
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]"
                    size={20}
                  />
                  <input
                    type="text"
                    value={newCode.code}
                    onChange={(e) =>
                      setNewCode({ ...newCode, code: e.target.value })
                    }
                    className="w-full bg-[#1A1C23] text-white border-2 border-[#3D4149] rounded-xl py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors text-lg group-hover:border-[#611BE2]/50"
                    placeholder="Enter promo code"
                  />
                </div>

                <select
                  value={newCode.category}
                  onChange={(e) =>
                    handleCategoryChange(e.target.value as PromoCodeType)
                  }
                  className="w-full bg-[#1A1C23] text-white border-2 border-[#3D4149] rounded-xl py-3 px-4 focus:outline-none focus:border-[#611BE2] transition-colors text-lg hover:border-[#611BE2]/50"
                >
                  <option value="Deposit Bonus">Deposit Bonus</option>
                  <option value="Free Box">Free Box</option>
                  <option value="Balance">Balance</option>
                </select>

                {newCode.category === "Deposit Bonus" ? (
                  <div className="relative group">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2] text-xl">
                      %
                    </span>
                    <input
                      type="number"
                      value={newCode.amount}
                      onChange={(e) =>
                        setNewCode({
                          ...newCode,
                          amount: parseFloat(e.target.value),
                        })
                      }
                      className="w-full bg-[#1A1C23] text-white border-2 border-[#3D4149] rounded-xl py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors text-lg group-hover:border-[#611BE2]/50"
                      placeholder="Enter deposit bonus percentage"
                      max="100"
                      min="0"
                    />
                  </div>
                ) : newCode.category === "Free Box" ? (
                  <div className="space-y-4">
                    {selectedBox ? (
                      <div className="flex items-center space-x-4 p-3 bg-[#1A1C23] rounded-xl border-2 border-[#611BE2]">
                        <img
                          src={selectedBox.image}
                          alt={selectedBox.name}
                          className="w-12 h-12 object-cover rounded-lg"
                        />
                        <div className="flex-1">
                          <div className="text-white font-medium">
                            {selectedBox.name}
                          </div>
                          <div className="text-sm text-gray-400">
                            {selectedBox.categories
                              .map((cat) => cat.name)
                              .join(", ")}
                          </div>
                        </div>
                        <button
                          onClick={() => setSelectedBox(null)}
                          className="text-[#611BE2] hover:underline"
                        >
                          Change
                        </button>
                      </div>
                    ) : (
                      <BoxSelector onSelect={handleBoxSelect} />
                    )}
                  </div>
                ) : (
                  <div className="relative group">
                    <EuroIcon
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]"
                      size={20}
                    />
                    <input
                      type="number"
                      value={newCode.amount}
                      onChange={(e) =>
                        setNewCode({
                          ...newCode,
                          amount: parseFloat(e.target.value),
                        })
                      }
                      className="w-full bg-[#1A1C23] text-white border-2 border-[#3D4149] rounded-xl py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors text-lg group-hover:border-[#611BE2]/50"
                      placeholder="Enter balance amount"
                    />
                  </div>
                )}

                <div className="relative group">
                  <input
                    type="text"
                    value={newCode.requirements}
                    onChange={(e) =>
                      setNewCode({ ...newCode, requirements: e.target.value })
                    }
                    className="w-full bg-[#1A1C23] text-white border-2 border-[#3D4149] rounded-xl py-3 px-4 focus:outline-none focus:border-[#611BE2] transition-colors text-lg group-hover:border-[#611BE2]/50"
                    placeholder="Enter requirements (optional)"
                  />
                </div>

                <div className="relative group">
                  <User
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#611BE2]"
                    size={20}
                  />
                  <input
                    type="number"
                    value={newCode.usage}
                    onChange={(e) =>
                      setNewCode({
                        ...newCode,
                        usage: parseFloat(e.target.value),
                      })
                    }
                    className="w-full bg-[#1A1C23] text-white border-2 border-[#3D4149] rounded-xl py-3 px-4 pl-12 focus:outline-none focus:border-[#611BE2] transition-colors text-lg group-hover:border-[#611BE2]/50"
                    placeholder="Enter usage max"
                  />
                </div>

                <motion.button
                  onClick={handleAddCode}
                  className="w-full bg-gradient-to-r from-[#611BE2] to-[#8B5CF6] text-white py-4 rounded-xl font-bold text-lg shadow-lg shadow-[#611BE2]/20 hover:shadow-[#611BE2]/40 transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  disabled={
                    !newCode.code ||
                    (newCode.category === "Free Box" && !selectedBox) ||
                    (newCode.category !== "Free Box" && newCode.amount <= 0)
                  }
                >
                  Set Promo Code
                </motion.button>
              </div>
            </CardContent>
          </Card>
        </div>
        <ActiveCodeDisplay
          code={currentPromoCode}
          showOverlay={showOverlay}
          onCodeUpdate={fetchCurrentCode}
        />
        <div className="flex items-center justify-between mb-12">
          <div className="flex items-center space-x-12">
            <div className="flex items-center space-x-2 text-gray-400">
              <Monitor size={20} />
              <span>Overlay Status:</span>
              <span
                className={`font-medium ${
                  showOverlay ? "text-green-500" : "text-red-500"
                }`}
              >
                {showOverlay ? "Active" : "Hidden"}
              </span>
            </div>
          </div>
          <motion.button
            onClick={handleToggleOverlay}
            className={`flex items-center space-x-3 px-6 py-3 rounded-xl transition-all duration-300 ${
              showOverlay
                ? "bg-red-500/20 text-red-500 hover:bg-red-500/30"
                : "bg-emerald-500/20 text-emerald-500 hover:bg-emerald-500/30"
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Power size={20} />
            <span className="font-medium">
              {showOverlay ? "Hide Overlay" : "Show Overlay"}
            </span>
          </motion.button>
        </div>
        <Card>
          <CardHeader className="bg-gradient-to-r from-[#611BE2]/10 to-transparent">
            <CardTitle>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <History className="mr-3 text-[#611BE2]" size={24} />
                  Promo Code History
                </div>
                <span className="text-sm text-gray-400">
                  {pagination.total} codes total
                </span>
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4 max-h-[400px] overflow-y-auto custom-scrollbar pr-2">
              {isLoading && history.length === 0 ? (
                <div className="text-center py-8">
                  <div className="animate-spin text-[#611BE2] mb-4">
                    <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  </div>
                  <p className="text-gray-400">Loading history...</p>
                </div>
              ) : (
                <AnimatePresence>
                  {history.map((entry) => (
                    <motion.div
                      key={entry.id}
                      className="bg-[#1A1C23] p-4 rounded-xl border border-[#3D4149] hover:border-[#611BE2]/50 transition-colors group"
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <div className="text-white font-medium flex items-center">
                            {entry.type === "Deposit Bonus" && (
                              <span className="mr-2 text-[#611BE2]">%</span>
                            )}
                            {entry.type === "Free Box" && (
                              <Gift className="mr-2 text-[#611BE2]" size={16} />
                            )}
                            {entry.type === "Balance" && (
                              <EuroIcon
                                className="mr-2 text-[#611BE2]"
                                size={16}
                              />
                            )}
                            <span>{entry.code}</span>
                            <span
                              className={`ml-2 px-2 py-0.5 rounded-full text-xs ${
                                entry.status === "active"
                                  ? "bg-green-500/10 text-green-500"
                                  : entry.status === "used"
                                  ? "bg-blue-500/10 text-blue-500"
                                  : "bg-red-500/10 text-red-500"
                              }`}
                            >
                              {entry.status.charAt(0).toUpperCase() +
                                entry.status.slice(1)}
                            </span>
                          </div>
                          <div className="text-sm text-gray-400 mt-2 flex items-center flex-wrap gap-2">
                            <span className="inline-flex items-center bg-[#611BE2]/10 rounded-full px-2 py-0.5">
                              <Clock
                                size={12}
                                className="mr-1 text-[#611BE2]"
                              />
                              {new Date(entry.createdAt).toLocaleString()}
                            </span>
                            {entry.usedBy && (
                              <span className="flex items-center bg-blue-500/10 text-blue-500 rounded-full px-2 py-0.5">
                                Used by {entry.usedBy}
                              </span>
                            )}
                            {entry.requirements && (
                              <span className="flex items-center bg-purple-500/10 text-purple-500 rounded-full px-2 py-0.5">
                                {entry.requirements}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-right">
                          <div className="text-[#611BE2] font-medium text-lg group-hover:scale-110 transition-transform">
                            {entry.type === "Deposit Bonus"
                              ? `${entry.value}%`
                              : `${entry.value}€`}
                          </div>
                          <div className="text-xs text-gray-500 mt-1">
                            {entry.type}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              )}

              {!isLoading && history.length === 0 && (
                <div className="text-center py-12">
                  <History className="w-12 h-12 text-[#611BE2]/30 mx-auto mb-4" />
                  <p className="text-gray-400">No promo codes in history</p>
                </div>
              )}

              {error && (
                <div className="text-center py-8">
                  <p className="text-red-500">{error}</p>
                  <button
                    onClick={() => fetchHistory(pagination.page)}
                    className="mt-2 text-[#611BE2] hover:underline"
                  >
                    Try again
                  </button>
                </div>
              )}
            </div>

            {pagination.totalPages > 1 && (
              <div className="mt-6 flex justify-center items-center space-x-4">
                <motion.button
                  onClick={handlePreviousPage}
                  disabled={pagination.page === 1}
                  className="px-4 py-2 bg-[#611BE2]/10 text-[#611BE2] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Previous
                </motion.button>
                <span className="text-gray-400">
                  Page {pagination.page} of {pagination.totalPages}
                </span>
                <motion.button
                  onClick={handleNextPage}
                  disabled={pagination.page === pagination.totalPages}
                  className="px-4 py-2 bg-[#611BE2]/10 text-[#611BE2] rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Next
                </motion.button>
              </div>
            )}
          </CardContent>
        </Card>
        {/* Toast Container */}
        <div className="fixed bottom-4 right-4 space-y-2 z-50">
          <AnimatePresence>
            {toasts.map((toast) => (
              <motion.div
                key={toast.id}
                initial={{ opacity: 0, y: 20, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: -20, scale: 0.95 }}
                className={`p-4 rounded-lg shadow-lg flex items-center space-x-2 ${
                  toast.type === "success"
                    ? "bg-green-500 text-white"
                    : "bg-red-500 text-white"
                }`}
              >
                {toast.type === "success" ? (
                  <CheckCircle2 className="w-5 h-5" />
                ) : (
                  <AlertCircle className="w-5 h-5" />
                )}
                <span className="font-medium">{toast.message}</span>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        <style>{`
        .custom-scrollbar::-webkit-scrollbar {
          width: 6px;
        }
        .custom-scrollbar::-webkit-scrollbar-track {
          background: #1A1C23;
          border-radius: 8px;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb {
          background: #3D4149;
          border-radius: 8px;
          border: 2px solid #1A1C23;
        }
        .custom-scrollbar::-webkit-scrollbar-thumb:hover {
          background: #611BE2;
        }
        
        @keyframes pulse-border {
          0% {
            border-color: rgba(97, 27, 226, 0.2);
          }
          50% {
            border-color: rgba(97, 27, 226, 0.5);
          }
          100% {
            border-color: rgba(97, 27, 226, 0.2);
          }
        }
        
        .active-code-pulse {
          animation: pulse-border 2s infinite;
        }
      `}</style>
      </div>
    </div>
  );
};

export default PromoCodeManager;
