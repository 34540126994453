import { useEffect, useRef, useState } from "react";
import { ChatMessage, PusherMessage, WinnerHistory } from "./types";
import { API_URL } from "components/api_config";
import { Trophy } from "lucide-react";
import { motion } from "framer-motion";

const WinnerChat: React.FC = () => {
  const [winners, setWinners] = useState<WinnerHistory[]>([]);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const chatRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchWinners = async () => {
      try {
        const response = await fetch(`${API_URL}/history-winner`);
        if (!response.ok) throw new Error("Failed to fetch winners");
        const data = await response.json();
        setWinners(data.slice(0, 6));
      } catch (err) {
        console.error("Error fetching winners:", err);
      }
    };

    fetchWinners();
    const interval = setInterval(fetchWinners, 4000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const ws = new WebSocket(
      "wss://ws-us2.pusher.com/app/32cbd69e4b950bf97679?protocol=7&client=js&version=8.4.0-rc2&flash=false"
    );

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          event: "pusher:subscribe",
          data: { auth: "", channel: "chatrooms.29877896.v2" },
        })
      );
    };

    ws.onmessage = (event) => {
      const data: PusherMessage = JSON.parse(event.data);
      if (data.event === "App\\Events\\ChatMessageEvent") {
        const messageData = JSON.parse(data.data);
        setMessages((prev) => [...prev, messageData]);

        if (chatRef.current) {
          chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
      }
    };

    return () => ws.close();
  }, []);

  return (
    <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
      <style>
        {`
        .scrollbar-custom {
          scrollbar-width: thin;
          scrollbar-color: rgba(139, 92, 246, 0.3) rgba(0, 0, 0, 0.2);
        }

        .scrollbar-custom::-webkit-scrollbar {
          width: 6px;
        }

        .scrollbar-custom::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 3px;
        }

        .scrollbar-custom::-webkit-scrollbar-thumb {
          background-color: rgba(139, 92, 246, 0.3);
          border-radius: 3px;
          transition: background-color 0.2s;
        }

        .scrollbar-custom::-webkit-scrollbar-thumb:hover {
          background-color: rgba(139, 92, 246, 0.5);
        }
      `}
      </style>
      <div className="flex items-center gap-2 mb-4">
        <Trophy className="text-amber-400" size={20} />
        <h2 className="text-xl font-semibold text-white">Winners Chat</h2>
      </div>

      <div className="h-[300px] overflow-y-auto space-y-2 scrollbar-custom">
        {messages
          .filter((msg) =>
            winners.some((winner) => winner.winnerName === msg.sender.username)
          )
          .map((msg) => (
            <motion.div
              key={msg.id}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              className="flex items-start gap-2 p-2 rounded-lg bg-amber-500/10 border border-amber-500/20"
            >
              <div className="flex items-center space-x-2">
                <img
                  src={
                    winners.find((w) => w.winnerName === msg.sender.username)
                      ?.pfp
                  }
                  alt={msg.sender.username}
                  className="w-6 h-6 rounded-md border border-amber-500/20"
                />
                <span
                  className="font-medium"
                  style={{ color: msg.sender.identity.color }}
                >
                  {msg.sender.username}:
                </span>
              </div>
              <span className="text-white">{msg.content}</span>
            </motion.div>
          ))}
      </div>
    </div>
  );
};

export default WinnerChat;
