import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import gsap from 'gsap';
import debounce from 'lodash/debounce';
import { 
  Box, Gift, Settings, ChevronLeft, ChevronRight, Search, 
  ToggleLeft, ToggleRight, Filter, Loader2, AlertCircle, RefreshCw,
  DollarSign, Package, LayersIcon, CheckCircle,
  Eye,
  EyeOff,
  Sliders,
  X,
  RotateCcw,
  SlidersHorizontal
} from 'lucide-react';
import { API_URL } from 'components/api_config';
import axios from 'axios';
import ModeSelector from './ModeSelector';
import FilterMenu from './utils/Filters';
import WaysSelector from './WaysSelector';
import toast from 'react-hot-toast';

// Types definitions
interface Tag {
  name: string;
  slug: string;
  color: string;
}


interface FilterState {
  priceMin: number;
  priceMax: number;
  categories: string[];
}

interface Category {
  name: string;
  slug: string;
  type: string;
}

interface BoxData {
  name: string;
  slug: string;
  image: string;
  price: number;
  tags: Tag[];
  enabled: boolean;
  categories: Category[];
}

// Composants utilitaires
const LoadingSpinner: React.FC = () => (
  <div className="flex items-center justify-center p-8">
    <Loader2 className="w-8 h-8 text-[#611BE2] animate-spin" />
  </div>
);

const ErrorMessage: React.FC<{ message: string; onRetry: () => void }> = ({ message, onRetry }) => (
  <div className="flex flex-col items-center justify-center p-8 bg-red-500/10 rounded-lg">
    <div className="flex items-center mb-4">
      <AlertCircle className="w-6 h-6 text-red-500 mr-2" />
      <span className="text-red-500">{message}</span>
    </div>
    <motion.button
      onClick={onRetry}
      className="flex items-center px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <RefreshCw className="w-4 h-4 mr-2" />
      Retry
    </motion.button>
  </div>
);

const StatCard: React.FC<{ 
  title: string; 
  value: string | number; 
  icon: React.ReactNode;
  index: number;
}> = ({ title, value, icon, index }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: index * 0.1 }}
    className="bg-[#2C3038] p-4 rounded-lg border border-[#3D4149] hover:border-[#611BE2] transition-all duration-300 group"
  >
    <div className="flex items-center justify-between mb-2">
      <p className="text-gray-400 text-sm">{title}</p>
      <div className="text-[#611BE2] group-hover:scale-110 transition-transform duration-300">
        {icon}
      </div>
    </div>
    <p className="text-2xl font-bold text-white">{value}</p>
  </motion.div>
);

const LoadingAnimation: React.FC = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex flex-col items-center justify-center"
  >
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.8, opacity: 0 }}
      className="text-center"
    >
      <motion.div
        className="w-24 h-24 mb-6 relative mx-auto"
        animate={{ rotate: 360 }}
        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
      >
        <div className="absolute inset-0 rounded-full border-4 border-t-[#611BE2] border-r-[#611BE2/30] border-b-[#611BE2/10] border-l-[#611BE2/60]" />
      </motion.div>
      <motion.h2 
        className="text-3xl font-bold text-white mb-4"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        Animation Started
      </motion.h2>
      <motion.div
        className="flex justify-center gap-1"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
      >
        {[0, 1, 2].map((i) => (
          <motion.div
            key={i}
            className="w-2 h-2 rounded-full bg-[#611BE2]"
            animate={{
              scale: [1, 1.5, 1],
              opacity: [1, 0.5, 1],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              delay: i * 0.2,
            }}
          />
        ))}
      </motion.div>
    </motion.div>
  </motion.div>
);



interface SliderProps {
  initialValue?: number;
  onValueChange?: (value: number) => void;
}

export function ImprovedSlider({ onValueChange }: SliderProps) {
  const [localValue, setLocalValue] = useState<number>(5);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const sliderRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const fetchInitialValue = async () => {
      try {
        const response = await fetch(API_URL +  '/number-boxes');
        if (!response.ok) throw new Error('Fetch failed');
        const data = await response.json();
        setLocalValue(data.value);
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    };
    
    fetchInitialValue();
  }, []);

  const debouncedUpdate = useCallback(
    debounce(async (value: number) => {
      try {
        const response = await fetch(API_URL +  '/number-boxes', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ value })
        });
        
        if (!response.ok) throw new Error('Update failed');
        onValueChange?.(value);
        setIsUpdating(false);
      } catch (error) {
        console.error('Error:', error);
        setIsUpdating(false);
      }
    }, 300),
    [onValueChange]
  );

  const calculateValue = (clientX: number): number => {
    if (!sliderRef.current) return localValue;
    
    const rect = sliderRef.current.getBoundingClientRect();
    const position = clientX - rect.left;
    const percentage = Math.max(0, Math.min(1, position / rect.width));
    return Math.round(percentage * 9 + 1);
  };

  const handleMove = useCallback((clientX: number) => {
    const newValue = calculateValue(clientX);
    if (newValue !== localValue) {
      setLocalValue(newValue);
      setIsUpdating(true);
      debouncedUpdate(newValue);
    }
  }, [localValue, debouncedUpdate]);

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true);
    handleMove(e.clientX);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setIsDragging(true);
    handleMove(e.touches[0].clientX);
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        handleMove(e.clientX);
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (isDragging) {
        handleMove(e.touches[0].clientX);
      }
    };

    const handleEnd = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [isDragging, handleMove]);

  if (isLoading) {
    return (
      <div className="mb-8 backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
        <div className="animate-pulse">Loading...</div>
      </div>
    );
  }

  return (
    <div className="mb-8 backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
      <div className="flex items-center gap-4 mb-4">
        <div className="p-3 bg-violet-500/10 rounded-xl">
          <SlidersHorizontal className="text-violet-400" size={24} />
        </div>
        <h2 className="text-xl font-semibold text-white">Number of Boxes to draw</h2>
      </div>
      
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <div 
            ref={sliderRef}
            className="relative w-full h-2 bg-violet-500/20 rounded-lg cursor-pointer"
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
          >
            <div 
              className="absolute h-full bg-violet-500 rounded-lg transition-all duration-200"
              style={{ width: `${(localValue - 1) * 11.11}%` }}
            />
            <div 
              className={`absolute w-4 h-4 bg-violet-500 rounded-full -mt-1 transition-all duration-200 ${isDragging ? 'scale-125' : 'hover:scale-110'}`}
              style={{ left: `calc(${(localValue - 1) * 11.11}% - 8px)` }}
            />
          </div>
          <span className="w-12 text-center text-white font-semibold">
            {isUpdating ? (
              <div className="animate-pulse">{localValue}</div>
            ) : localValue}
          </span>
        </div>
        
        <p className="text-gray-400 text-sm">
          {isUpdating ? 'Updating value...' : 'Drag the slider to set your value'}
        </p>
      </div>
    </div>
  );
}

const LatestBoxes: React.FC = () => {
  const [latestBoxes, setLatestBoxes] = useState<BoxData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const empireDropDataRef = useRef<any[]>([]);

  const findBoxByName = (boxes: any[], searchName: string) => {
    const normalizedSearch = searchName.toLowerCase().trim();
    let bestMatch = null;
    let bestScore = Infinity;

    for (const box of boxes) {
      const boxName = box.name.toLowerCase().trim();
      const distance = levenshteinDistance(normalizedSearch, boxName);
      if (distance < bestScore) {
        bestScore = distance;
        bestMatch = box;
      }
    }

    return bestMatch;
  };

  const levenshteinDistance = (str1: string, str2: string): number => {
    const m = str1.length;
    const n = str2.length;
    const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

    for (let i = 0; i <= m; i++) dp[i][0] = i;
    for (let j = 0; j <= n; j++) dp[0][j] = j;

    for (let i = 1; i <= m; i++) {
      for (let j = 1; j <= n; j++) {
        const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
        dp[i][j] = Math.min(
          dp[i - 1][j] + 1,
          dp[i][j - 1] + 1,
          dp[i - 1][j - 1] + cost
        );
      }
    }
    return dp[m][n];
  };

  const fetchLatestBoxes = async () => {
    try {
      if (empireDropDataRef.current.length === 0) {
        const empireResponse = await fetch('https://api.empiredrop.com/api/v1/boxes/search', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            brands: [],
            categories: [],
            order: 'CHEAPEST',
            price_max: 500000,
            price_min: 0,
            tags: [],
            page_size: 100000

          })
        });

        if (!empireResponse.ok) throw new Error('Failed to fetch EmpireDrop data');
        const empireBoxes = await empireResponse.json();
        empireDropDataRef.current = await empireBoxes.data;
      }

      const localResponse = await fetch(API_URL +  '/boxes');
      if (!localResponse.ok) throw new Error('Failed to fetch local boxes');
      
      const localData = await localResponse.json();
      const boxData = localData.data[0];
      
      // Extraire toutes les boxes non vides
      const boxNames = Object.entries(boxData)
        .filter(([key, value]) => key.startsWith('box') && value && value !== '')
        .map(([_, value]) => value as string);

      const matchingBoxes = boxNames.map(name => {
        const box = findBoxByName(empireDropDataRef.current, name);
        if (!box) {
          console.warn(`Box not found: ${name}`);
          return null;
        }

        return {
          name: box.name,
          slug: box.slug,
          image: box.image.startsWith('/') ? `https://cdn.empiredrop.com${box.image}` : `https://cdn.empiredrop.com/${box.image}`,
          price: box.price,
          tags: box.tags || [],
          enabled: true,
          categories: box.categories || []
        };
      }).filter(Boolean) as BoxData[];

      setLatestBoxes(matchingBoxes);
      setIsLoading(false);

      if (matchingBoxes.length < boxNames.length) {
        empireDropDataRef.current = [];
      }
    } catch (err) {
      console.error('Error fetching boxes:', err);
      setError(err instanceof Error ? err.message : 'Error fetching boxes');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestBoxes();
    const interval = setInterval(fetchLatestBoxes, 10000);
    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <div className="text-center py-8 text-gray-400">
        <Loader2 className="w-8 h-8 text-[#611BE2] animate-spin mx-auto" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-red-400">
        <AlertCircle className="w-8 h-8 mx-auto mb-2" />
        <p>{error}</p>
      </div>
    );
  }

  return (
<div className="grid grid-cols-6 divide-x divide-y divide-gray-800/50">
  {latestBoxes.map((box: any, index: any) => (
    <motion.div
      key={box.slug || index}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="bg-[#1A1C23] overflow-hidden group w-40"
    >
      <div className="relative aspect-[3/2]">
        <img 
          src={box.image} 
          alt={box.name}
          className="w-full h-full object-contain transition-transform duration-300 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      </div>
      <div className="p-3">
        <h3 className="text-white text-sm font-semibold truncate">{box.name}</h3>
        <p className="text-[#611BE2] text-sm font-medium mt-1">{(box.price / 100).toFixed(2)} €</p>
        <div className="flex flex-wrap gap-1 mt-1">
          {box.tags.slice(0, 2).map((tag: any ) => (
            <span
              key={tag.slug}
              className="px-2 py-0.5 rounded-full text-[11px] font-medium"
              style={{ backgroundColor: `#${tag.color}40` }}
            >
              {tag.name}
            </span>
          ))}
        </div>
      </div>
    </motion.div>
  ))}
</div>
  );
};
const RandomBoxSelector: React.FC<{ selectedBox: BoxData | null; onStart: () => void }> = ({ 
  selectedBox, 
  onStart 
 }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sliderValue, setSliderValue] = useState(5); // Default value
  const [isUpdatingSlider, setIsUpdatingSlider] = useState(false);
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
 
  const handleStart = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setShowLoadingAnimation(true);
      
      const response = await fetch(API_URL +  '/reset-and-activate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
 
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
 
      await new Promise(resolve => setTimeout(resolve, 10000));
 
      if (selectedBox) {
        const createBoxesResponse = await fetch(API_URL +  '/boxes', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            boxes: Array(5).fill({
              box1: selectedBox.name,
              box2: selectedBox.name,
              box3: selectedBox.name,
              box4: selectedBox.name,
              box5: selectedBox.name
            })
          })
        });
 
        if (!createBoxesResponse.ok) {
          throw new Error(`HTTP error! status: ${createBoxesResponse.status}`);
        }
 
        onStart();
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      console.error('Error during process:', err);
    } finally {
      setIsLoading(false);
      setShowLoadingAnimation(false);
    }
  };
 
  const handleResetV2 = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await fetch(API_URL +  '/reset-all', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
 
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
 
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
      console.error('Error during reset:', err);
    } finally {
      setIsLoading(false);
    }
  };
 
  return (
    <>
      <AnimatePresence>
        {showLoadingAnimation && <LoadingAnimation />}
      </AnimatePresence>
    
      <motion.div
        className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />

        {/* Header Section */}
        
        <div className="flex items-center justify-between mb-8">
          

        <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
          Random Box Selector
                </h1>
        </div>

        {/* Latest Boxes Section */}
        <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6 mb-6">
          <LatestBoxes />
        </div>

        {/* Error Message */}
        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-xl text-red-400 text-sm"
          >
            {error}
          </motion.div>
        )}

        {/* Start Button */}
        <motion.button
          onClick={handleStart}
          disabled={isLoading}
          className={`relative w-full group ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
          whileHover={{ scale: isLoading ? 1 : 1.02 }}
          whileTap={{ scale: isLoading ? 1 : 0.98 }}
        >
          {/* Button Background with Gradient Border */}
          <div className="absolute inset-0 bg-gradient-to-r from-violet-600 to-violet-400 rounded-xl" />
          <div className="absolute inset-[1px] bg-gradient-to-r from-violet-950 to-gray-900 rounded-[11px]" />
          
          {/* Button Content */}
          <div className="relative px-8 py-4">
            <span className="relative z-10 flex items-center justify-center gap-3 text-lg font-semibold">
              {isLoading ? (
                <>
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle 
                      className="opacity-25" 
                      cx="12" 
                      cy="12" 
                      r="10" 
                      stroke="currentColor" 
                      strokeWidth="4" 
                      fill="none" 
                    />
                    <path 
                      className="opacity-75" 
                      fill="currentColor" 
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" 
                    />
                  </svg>
                  <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                    Processing...
                  </span>
                </>
              ) : (
                <>
                  <Gift size={20} className="text-violet-400" />
                  <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                    Start Random Selection
                  </span>
                </>
              )}
            </span>
          </div>

          {/* Hover Effect Overlay */}
          <div className="absolute inset-0 bg-gradient-to-r from-violet-400 to-violet-600 opacity-0 group-hover:opacity-20 transition-opacity duration-500 rounded-xl" />
        </motion.button>
        <br/>
        <motion.button
    onClick={async () => {
      try {
        const response = await fetch('https://empire.selaris.app/api/v1/box/reset', {
          method: 'GET'
        });
        if (!response.ok) throw new Error('Failed to stop');
        toast.success('Successfully stopped');
      } catch (error) {
        console.error('Error stopping:', error);
        toast.error('Failed to stop');
      }
    }}
    className="relative w-full group"
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
  >
    {/* Button Background with Gradient Border */}
    <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-red-400 rounded-xl" />
    <div className="absolute inset-[1px] bg-gradient-to-r from-red-950 to-gray-900 rounded-[11px]" />
    
    {/* Button Content */}
    <div className="relative px-8 py-4">
      <span className="relative z-10 flex items-center justify-center gap-3 text-lg font-semibold">
        <X size={20} className="text-red-400" />
        <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
          Stop
        </span>
      </span>
    </div>
    <div className="absolute inset-0 bg-gradient-to-r from-red-400 to-red-600 opacity-0 group-hover:opacity-20 transition-opacity duration-500 rounded-xl" />
    </motion.button>
      </motion.div>
    </>
  );
 };

const BoxCard: React.FC<{
  box: BoxData;
  onToggle: (slug: string) => void;
}> = ({ box, onToggle }) => {
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current) {
      gsap.fromTo(cardRef.current,
        { 
          opacity: 0,
          y: 20
        },
        { 
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power3.out"
        }
      );
    }
  }, []);

  return (
    <div
      ref={cardRef}
      className="box-card bg-gradient-to-br from-[#1A1C23] to-[#252A34] p-4 rounded-xl border border-[#3D4149] hover:border-[#611BE2] transition-all duration-300 group"
    >
      <div className="flex gap-4">
        <div className="relative group/image">
          <img 
            src={box.image} 
            alt={box.name}
            className="w-24 h-24 rounded-lg object-cover transition-all duration-300 group-hover/image:scale-105 group-hover/image:shadow-lg"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-lg opacity-0 group-hover/image:opacity-100 transition-opacity duration-300" />
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-white mb-1">{box.name}</h3>
          <p className="text-[#611BE2] font-medium">{(box.price / 100).toFixed(2)} €</p>
          <div className="flex flex-wrap gap-2 mt-2">
            {box.tags.map((tag) => (
              <span
                key={tag.slug}
                className="px-2 py-1 rounded-full text-xs font-medium relative overflow-hidden group/tag"
                style={{ backgroundColor: `#${tag.color}40` }}
              >
                <span className="relative z-10">{tag.name}</span>
                <div 
                  className="absolute inset-0 opacity-20 group-hover/tag:opacity-30 transition-opacity duration-300"
                  style={{ backgroundColor: `#${tag.color}` }}
                />
              </span>
            ))}
          </div>
        </div>
        <motion.button
          onClick={() => onToggle(box.slug)}
          className="self-start p-2 rounded-lg hover:bg-[#611BE2]/10 transition-all duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          {box.enabled ? (
            <ToggleRight className="w-6 h-6 text-[#611BE2]" />
          ) : (
            <ToggleLeft className="w-6 h-6 text-gray-400" />
          )}
        </motion.button>
      </div>
    </div>
  );
};

const BoxGrid: React.FC<{ 
    boxes: BoxData[]; 
    onToggle: (slug: string) => void;
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
  }> = ({ boxes, onToggle, currentPage, totalPages, onPageChange }) => {
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {boxes.map((box, index) => (
            <BoxCard 
              key={box.slug} 
              box={box} 
              onToggle={onToggle}
            />
          ))}
        </div>
  
        {totalPages > 1 && (
          <div className="flex justify-center items-center gap-4 mt-8">
            <motion.button
              onClick={() => onPageChange(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className="p-2 rounded-lg text-[#611BE2] disabled:text-gray-600 hover:bg-[#611BE2]/10 transition-all duration-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <ChevronLeft size={24} />
            </motion.button>
            <span className="text-white font-medium">
              Page {currentPage} of {totalPages}
            </span>
            <motion.button
              onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
              disabled={currentPage === totalPages}
              className="p-2 rounded-lg text-[#611BE2] disabled:text-gray-600 hover:bg-[#611BE2]/10 transition-all duration-300"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <ChevronRight size={24} />
            </motion.button>
          </div>
        )}
      </div>
    );
  };
  

const BoxManager: React.FC = () => {
  const [boxes, setBoxes] = useState<BoxData[]>([]);
  const [selectedBox, setSelectedBox] = useState<BoxData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isBulkUpdating, setIsBulkUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sliderValue, setSliderValue] = useState<number>(5);
  const [isUpdatingSlider, setIsUpdatingSlider] = useState(false);
  const ITEMS_PER_PAGE = 15;

  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [filters, setFilters] = useState<FilterState>({
    priceMin: 0,
    priceMax: 500000,
    categories: []
  });

  // Fetch initial slider value
  const fetchSliderValue = async () => {
    try {
      const response = await fetch(API_URL +  '/number-boxes');
      if (!response.ok) throw new Error('Failed to fetch value');
      const data = await response.json();
      setSliderValue(data.value);
    } catch (error) {
      console.error('Error fetching slider value:', error);
    }
  };

  const fetchPriceRange = async () => {
    try {
      const response = await fetch(API_URL +  '/filters/price-range');
      if (!response.ok) throw new Error('Failed to fetch price range');
  
      const data = await response.json();
      setFilters((prevFilters) => ({
        ...prevFilters,
        priceMin: data.minPrice * 100, // Conversion en centimes si nécessaire
        priceMax: data.maxPrice * 100,
      }));
    } catch (error) {
      console.error('Error fetching price range:', error);
    }
  };

  // Update slider value
  const updateSliderValue = async (value: number) => {
    setIsUpdatingSlider(true);
    try {
      const response = await fetch(API_URL +  '/number-boxes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ value })
      });

      if (!response.ok) throw new Error('Failed to update value');
      
      const data = await response.json();
      setSliderValue(data.value);
    } catch (error) {
      console.error('Error updating slider value:', error);
      // Revert to previous value on error
      fetchSliderValue();
    } finally {
      setIsUpdatingSlider(false);
    }
  };

  const availableCategories = useMemo(() => {
    const categoriesSet = new Set<string>();
    const categoriesArray: { name: string; slug: string; type: string; }[] = [];
    
    boxes.forEach(box => {
      box.categories.forEach(category => {
        const categoryKey = JSON.stringify(category);
        if (!categoriesSet.has(categoryKey)) {
          categoriesSet.add(categoryKey);
          categoriesArray.push(category);
        }
      });
    });
    
    return categoriesArray;
  }, [boxes]);

  const filteredBoxes = useMemo(() => {
    return boxes.filter(box => {
      const searchLower = searchTerm.toLowerCase().trim();
      const matchesSearch = !searchLower || 
        box.name.toLowerCase().includes(searchLower) ||
        box.categories.some(cat => cat.name.toLowerCase().includes(searchLower)) ||
        box.tags.some(tag => tag.name.toLowerCase().includes(searchLower));

      const matchesPrice = box.price >= filters.priceMin && box.price <= filters.priceMax;

      const matchesCategory = filters.categories.length === 0 ||
        box.categories.some(cat => filters.categories.includes(cat.slug));

      return matchesSearch && matchesPrice && matchesCategory;
    });
  }, [boxes, searchTerm, filters]);

  const renderStats = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
      <StatCard 
        title="Total Boxes" 
        value={filteredBoxes.length}
        icon={<Package size={20} />}
        index={0}
      />
      <StatCard 
        title="Enabled Boxes" 
        value={filteredBoxes.filter(box => box.enabled).length}
        icon={<CheckCircle size={20} />}
        index={1}
      />
      <StatCard 
        title="Price Range" 
        value={`${(filters.priceMin / 100).toFixed(2)}€ - ${(filters.priceMax / 100).toFixed(2)}€`}
        icon={<DollarSign size={20} />}
        index={2}
      />
      <StatCard 
        title="Active Filters" 
        value={filters.categories.length + (filters.priceMin > 0 || filters.priceMax < 500000 ? 1 : 0)}
        icon={<Filter size={20} />}
        index={3}
      />
    </div>
  );

  const renderBulkControls = () => (
    <div className="flex items-center gap-4">
      <motion.button
        onClick={() => handleBulkToggle(true)}
        disabled={isBulkUpdating}
        className="flex items-center gap-2 px-4 py-2 bg-emerald-500 text-white rounded-lg hover:bg-emerald-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        whileHover={{ scale: isBulkUpdating ? 1 : 1.05 }}
        whileTap={{ scale: isBulkUpdating ? 1 : 0.95 }}
      >
        <Eye size={18} />
        Enable All
      </motion.button>
      <motion.button
        onClick={() => handleBulkToggle(false)}
        disabled={isBulkUpdating}
        className="flex items-center gap-2 px-4 py-2 bg-[#EF4444] text-white rounded-lg hover:bg-[#DC2626] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        whileHover={{ scale: isBulkUpdating ? 1 : 1.05 }}
        whileTap={{ scale: isBulkUpdating ? 1 : 0.95 }}
      >
        <EyeOff size={18} />
        Disable All
      </motion.button>
    </div>
  );

  const renderSlider = () => (
    <div className="mb-8 backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
      <div className="flex items-center gap-4 mb-4">
        <div className="p-3 bg-violet-500/10 rounded-xl">
          <SlidersHorizontal className="text-violet-400" size={24} />
        </div>
        <h2 className="text-xl font-semibold text-white">Number of Boxes to draw</h2>
      </div>
      
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <input
            type="range"
            min="1"
            max="10"
            value={sliderValue}
            onChange={(e) => updateSliderValue(parseInt(e.target.value))}
            className="w-full h-2 bg-violet-500/20 rounded-lg appearance-none cursor-pointer
                       [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 
                       [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-violet-500 
                       [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:cursor-pointer
                       [&::-webkit-slider-thumb]:transition-all [&::-webkit-slider-thumb]:duration-200
                       [&::-webkit-slider-thumb]:hover:bg-violet-400"
          />
          <span className="w-12 text-center text-white font-semibold">
            {isUpdatingSlider ? (
              <motion.div
                animate={{ opacity: [1, 0.5, 1] }}
                transition={{ repeat: Infinity, duration: 1 }}
              >
                {sliderValue}
              </motion.div>
            ) : (
              sliderValue
            )}
          </span>
        </div>
        
        <p className="text-gray-400 text-sm">
          {isUpdatingSlider ? 'Updating value...' : 'Drag the slider to set your value'}
        </p>
      </div>
    </div>
  );

  const fetchDisabledBoxes = async () => {
    try {
      const response = await fetch(API_URL +  '/disabled');
      if (!response.ok) throw new Error('Failed to fetch disabled boxes');
      const data = await response.json();
      return new Set(data.data.map((box: any) => box.slug));
    } catch (error) {
      console.error('Error fetching disabled boxes:', error);
      return new Set();
    }
  };

  const fetchBoxes = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [boxesResponse, disabledBoxes] = await Promise.all([
        fetch('https://api.empiredrop.com/api/v1/boxes/search', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            brands: [],
            categories: [],
            order: 'CHEAPEST',
            price_max: 500000,
            price_min: 0,
            tags: [],
            page_size: 100000
          })
        }),
        fetchDisabledBoxes()
      ]);

      if (!boxesResponse.ok) {
        throw new Error('Failed to fetch boxes');
      }
      const preData = await boxesResponse.json();
      const data = await preData.data;
      
      const transformedBoxes: BoxData[] = data.map((box: any) => ({
        name: box.name,
        slug: box.slug,
        image: box.image.startsWith('/')
          ? `https://cdn.empiredrop.com${box.image}`
          : `https://cdn.empiredrop.com/${box.image}`,
        price: box.price,
        tags: box.tags || [],
        enabled: !disabledBoxes.has(box.slug),
        categories: box.categories || []
      }));

      setBoxes(transformedBoxes);
    } catch (err) {
      console.error('Error fetching boxes:', err);
      setError('Failed to load boxes. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBoxes();
    fetchPriceRange();
    fetchSliderValue();
  }, []);




  const handleBulkToggle = async (enableAll: boolean) => {
    try {
      setIsBulkUpdating(true);
      
      setBoxes(prevBoxes => 
        prevBoxes.map(box => ({
          ...box,
          enabled: enableAll
        }))
      );

      if (enableAll) {
        await axios.post(API_URL + '/toggle-all');
      } else {
        await axios.post(API_URL + '/disable-all');
      }

    } catch (error) {
      console.error('Error during bulk update:', error);
      fetchBoxes();
    } finally {
      setIsBulkUpdating(false);
    }
  };

  const totalPages = Math.ceil(filteredBoxes.length / ITEMS_PER_PAGE);
  const currentBoxes = filteredBoxes.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleStartRandom = () => {
    const enabledBoxes = boxes.filter(box => box.enabled);
    if (enabledBoxes.length > 0) {
      const randomIndex = Math.floor(Math.random() * enabledBoxes.length);
      setSelectedBox(enabledBoxes[randomIndex]);
    }
  };

  const handleToggleBox = async (slug: string) => {
    try {
      const box = boxes.find(b => b.slug === slug);
      if (!box) return;
  
      const newEnabled = !box.enabled;
      
      await fetch(`https://api.empiredrop.com/api/v1/boxes/search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          brands: [],
          categories: [],
          order: 'CHEAPEST',
          price_max: 500000,
          price_min: 0,
          tags: [],
          page_size: 100000
        })
      });
  
      const response = await fetch(`${API_URL}/toggle/${slug}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          isEnabled: newEnabled
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to toggle box state');
      }
  
      setBoxes(prevBoxes => 
        prevBoxes.map(box => 
          box.slug === slug ? { ...box, enabled: newEnabled } : box
        )
      );
    } catch (error) {
      console.error('Error toggling box:', error);
      fetchBoxes();
    }
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-violet-900/20 via-gray-900 to-black p-6">
      <RandomBoxSelector selectedBox={selectedBox} onStart={handleStartRandom} />
      <br/>
      <div className="max-w-8xl mx-auto">
        <div className="relative overflow-hidden rounded-2xl bg-gradient-to-r from-violet-950/50 to-gray-900/50 p-8 backdrop-blur-xl border border-gray-800/50 mb-8">
          <div className="absolute inset-0 bg-grid-white/[0.02] -z-10" />
          <div className="flex items-center gap-4">
            <div className="p-3 bg-violet-500/10 rounded-2xl ring-1 ring-violet-500/20">
              <Box className="w-8 h-8 text-violet-400" />
            </div>
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                Available Boxes
              </h1>
              <p className="text-gray-400 text-sm mt-1">
                Browse and select from EmpireDrop collection
              </p>
            </div>
          </div>
        </div>
        <ModeSelector />
        <WaysSelector />
        <ImprovedSlider 
  initialValue={sliderValue} 
  onValueChange={(value) => {
    setSliderValue(value);
    setIsUpdatingSlider(false);
  }} 
/>


        <div className="backdrop-blur-xl bg-gray-900/30 border border-gray-800/50 rounded-xl p-6">
          {!isLoading && !error && boxes.length > 0 && renderStats()}

          <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-8">
            <div className="flex items-center gap-4">
              <div className="p-3 bg-violet-500/10 rounded-xl">
                <Box className="text-violet-400" size={24} />
              </div>
              <h2 className="text-xl font-semibold text-white">Boxes Collection</h2>
            </div>

            <div className="flex flex-col sm:flex-row items-end sm:items-center gap-4">
              {renderBulkControls()}
              
              {/* Search Input */}
              <div className="relative flex-1 sm:flex-none">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-violet-400" size={20} />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full sm:w-64 bg-black/20 text-white pl-10 pr-4 py-2 rounded-lg
                           border border-violet-500/20 focus:border-violet-500 focus:outline-none
                           transition-all duration-300"
                  placeholder="Search boxes, categories, tags..."
                />
              </div>

              {/* Filter Button */}
              <div className="relative">
                <motion.button 
                  onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}
                  className={`p-3 rounded-lg transition-all duration-300 ${
                    isFilterMenuOpen || filters.categories.length > 0 || filters.priceMin > 0 || filters.priceMax < 500000
                      ? 'bg-violet-600 text-white shadow-lg shadow-violet-500/20'
                      : 'bg-violet-500/10 text-violet-400 hover:bg-violet-500/20'
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Filter size={20} />
                </motion.button>
                <AnimatePresence>
                  {isFilterMenuOpen && (
                    <FilterMenu
                      isOpen={isFilterMenuOpen}
                      onClose={() => setIsFilterMenuOpen(false)}
                      filters={filters}
                      onFiltersChange={setFilters}
                      availableCategories={availableCategories}
                      boxes={[]}
                    />
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>

          <AnimatePresence mode="wait">
            {isLoading ? (
              <LoadingSpinner />
            ) : error ? (
              <ErrorMessage message={error} onRetry={fetchBoxes} />
            ) : filteredBoxes.length === 0 ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="flex flex-col items-center justify-center py-12 text-gray-400"
              >
                <Box size={48} className="text-violet-400 mb-4" />
                {searchTerm || filters.categories.length > 0 || filters.priceMin > 0 || filters.priceMax < 500000 ? (
                  <>
                    <p className="text-lg mb-2">No boxes found matching your filters</p>
                    <motion.button
                      onClick={() => {
                        setSearchTerm('');
                        setFilters({
                          priceMin: 0,
                          priceMax: 500000,
                          categories: []
                        });
                      }}
                      className="flex items-center px-6 py-3 bg-violet-600 text-white rounded-xl
                               hover:bg-violet-500 transition-all duration-200 transform hover:scale-105
                               active:scale-95 shadow-lg shadow-violet-500/20"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Clear all filters
                    </motion.button>
                  </>
                ) : (
                  <p className="text-lg">No boxes available</p>
                )}
              </motion.div>
            ) : (
              <BoxGrid
                boxes={currentBoxes}
                onToggle={handleToggleBox}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

  
  export default BoxManager;
