import React, { useState, useEffect } from 'react';
import { Search, ChevronLeft, ChevronRight } from 'lucide-react';
import { API_URL } from 'components/api_config';


interface KickUser {
  id: number;
  username: string;
  slug: string;
  profile_pic: string | null;
  is_staff: boolean;
  is_channel_owner: boolean;
  is_moderator: boolean;
  following_since: string | null;
  banned: boolean | null;
  draw_banned: boolean;
  createdAt: string | null;
  updatedAt: string;
}



const UsersTable: React.FC = () => {
  const [users, setUsers] = useState<KickUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<KickUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  
  const ITEMS_PER_PAGE = 15;
  const DEFAULT_PROFILE_PIC = "https://dbxmjjzl5pc1g.cloudfront.net/d4dc745f-3f57-4e58-9c10-c4fe21358f0c/images/user-profile-pic.png";

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user => 
      user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.id.toString().includes(searchQuery)
    );
    setFilteredUsers(filtered);
    setCurrentPage(1);
  }, [searchQuery, users]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(API_URL + '/viewers/viewers');
      if (!response.ok) throw new Error('Failed to fetch users');
      const data = await response.json();
      setUsers(data);
      setFilteredUsers(data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching users:', err);
      setLoading(false);
    }
  };

  const toggleDrawBan = async (userId: number, currentStatus: boolean) => {
    try {
      const response = await fetch(`${API_URL}/viewers/viewers/${userId}/draw_banned`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ draw_banned: !currentStatus }),
      });

      if (!response.ok) throw new Error('Failed to update draw ban status');

      setUsers(users.map(user => 
        user.id === userId ? { ...user, draw_banned: !currentStatus } : user
      ));
    } catch (err) {
      console.error('Error toggling draw ban:', err);
    }
  };

  const getInitials = (username: string) => {
    return username.slice(0, 2).toUpperCase();
  };

  const getDangerLevel = (followingDate: string | null) => {
    if (!followingDate) return 'low';
    const followDate = new Date(followingDate);
    const now = new Date();
    const diffDays = Math.floor((now.getTime() - followDate.getTime()) / (1000 * 60 * 60 * 24));
    
    if (diffDays < 7) return 'high';
    if (diffDays < 30) return 'medium';
    return 'low';
  };

  const dangerColorMap = {
    high: 'bg-red-500',
    medium: 'bg-orange-500',
    low: 'bg-green-500'
  };

  const totalPages = Math.ceil(filteredUsers.length / ITEMS_PER_PAGE);
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const maxDisplayedPages = 7;
  const getPageNumbers = () => {
    let pages = [];
    if (totalPages <= maxDisplayedPages) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      const leftOffset = Math.max(Math.min(currentPage - Math.floor(maxDisplayedPages / 2), totalPages - maxDisplayedPages), 0);
      pages = Array.from({ length: maxDisplayedPages }, (_, i) => i + 1 + leftOffset);
    }
    return pages;
  };

  return (
    <div className="min-h-screen bg-[#0f1116] p-6">
      <div className="max-w-7xl mx-auto">
        {/* Search Bar */}
        <div className="mb-6 relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search by username or ID..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 bg-gray-800/50 border border-gray-700 rounded-lg text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-violet-500"
          />
        </div>

        <div className="overflow-x-auto rounded-lg border border-gray-800">
          <table className="w-full">
            <thead className="bg-gray-800/50">
              <tr>
                <th className="py-3 px-6 text-left text-sm text-gray-400">User</th>
                <th className="py-3 px-6 text-center text-sm text-gray-400">Banned</th>
                <th className="py-3 px-6 text-center text-sm text-gray-400">Moderator</th>
                <th className="py-3 px-6 text-center text-sm text-gray-400">Draw Ban</th>
                <th className="py-3 px-6 text-left text-sm text-gray-400">Risk Level</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800">
              {paginatedUsers.map((user) => (
                <tr key={user.id} className="bg-gray-900/50">
                  <td className="py-3 px-6">
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center overflow-hidden">
                        {user.profile_pic ? (
                          <img src={user.profile_pic} alt="" className="w-full h-full object-cover" />
                        ) : (
                          <span className="text-violet-400 font-medium">{getInitials(user.username)}</span>
                        )}
                      </div>
                      <div>
                        <div className="text-gray-200">{user.username}</div>
                        <div className="text-sm text-gray-500">ID: {user.id}</div>
                      </div>
                    </div>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <span className="text-xl">
                      {user.banned == null ? '❌' : '✅'}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <span className="text-xl">
                      {user.is_moderator ? '✅' : '❌'}
                    </span>
                  </td>
                  <td className="py-3 px-6 text-center">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={user.draw_banned}
                        onChange={() => toggleDrawBan(user.id, user.draw_banned)}
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-violet-600"></div>
                    </label>
                  </td>
                  <td className="py-3 px-6">
                    <div className="flex items-center gap-2">
                      <div className={`w-3 h-3 rounded-full ${dangerColorMap[getDangerLevel(user.following_since)]}`}></div>
                      <span className="text-gray-400 text-sm">
                        {getDangerLevel(user.following_since) === 'high' ? 'High Risk' : 
                         getDangerLevel(user.following_since) === 'medium' ? 'Medium Risk' : 'Low Risk'}
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="flex justify-center items-center gap-2 mt-6">
          <button
            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
            className="p-2 rounded-lg bg-gray-800 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronLeft size={16} />
          </button>
          
          {getPageNumbers().map(page => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={`w-8 h-8 rounded-lg text-sm font-medium transition-colors ${
                currentPage === page
                  ? 'bg-violet-500 text-white'
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
              }`}
            >
              {page}
            </button>
          ))}

          <button
            onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
            disabled={currentPage === totalPages}
            className="p-2 rounded-lg bg-gray-800 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <ChevronRight size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsersTable;